import React, { useState, useEffect, useContext } from 'react';
import Typewriter from 'typewriter-effect';
import { BASE_URLS } from './urls';
import { AppContext } from '../../user/AppContext';

function JoinRevolation() {
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext)
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section id="main-banner-join" className='mt-3 '>
            <div className="container ">
                <div className="cx-banner-outer ">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-12 d-flex flex-row  align-items-center">

                            <div className='join-left-wrapper'>
                                <h3 className='text-light'>Join the Revolution</h3>
                                <p className='text-light'>We are changing the way families take over<br/> their assets. Financial & Non-Financial Asset<br/> Discovery in one click.</p>
                                
                                <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank"><button className='btn btn-primary'>Download Now</button> </a>
                            </div>

                            <div className='join-right-wrapper'>
                                <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/family+img.png' alt='Financial and Non - Financial Inheritance at your fingertips'></img>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </section>


    )
}

export default JoinRevolation;