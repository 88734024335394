
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert'

import { positions } from 'react-alert'
import ReactGA from 'react-ga';
import ReCAPTCHA from "react-google-recaptcha";
import LoadingBar from 'react-top-loading-bar';
import { PostCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';

//ReactGA.initialize(TRACKING_CODE);
const PageSectionOne = () => {
    const alert = useAlert()
    const [progress, setProgress] = useState(0)
    const [registerData, setRegisterData] = useState({ name: "", email: "" })
    const [verifed, setVerifed] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerifed(true);
    }
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited APP Landing  page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = "Landing page - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])
    useEffect(
        () => {
            setProgress(100)
        }, []
    )
    const handleRegister = async (e) => {
        e.preventDefault()
        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })

        PostCallWithErrorResponse(ApiConfig.APPLANDINGPHOMEEMAILFORM, registerData).then((data) => {
            console.log(data.json)
            if (data.json.status) {
                //console.log("first")
                setRegisterData({ name: "", email: "" })

                setTimeout(() => {
                    setProgress(25)
                }, 100);
                setTimeout(() => {
                    setProgress(70)
                }, 100);
                setTimeout(() => {
                    setProgress(100)
                }, 100);
            }
        })
            .catch((error) => {
                console.log("api response", error);
            });

    }
    const handleChange = (e) => {

        if (e.target.name === 'name') {

            setRegisterData({ ...registerData, name: e.target.value })
        }

        if (e.target.name === 'email') {

            setRegisterData({ ...registerData, email: e.target.value })
        }

    }

    return (
        <div>
            {/* <MetaTags>
                <title> Become our Service Partner|DigiWill</title>
                <meta name="description" content="Get a chance with us to cater to our customers & increase your business. Become our service partner." />
            </MetaTags> */}

            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <main></main>

            <div class="container col-xxl-8 px-4 py-5 ">

                <div id='desktop-view'>
                    {/* section 1 start */}
                    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div class="col-10 col-sm-8 col-lg-6">
                            <img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/img1.png" class="d-block mx-lg-auto img-fluid" alt="" loading="lazy" />
                        </div>
                        <div class="col-lg-6">
                            <div class="lc-block mb-3">
                                <div editable="rich">
                                    <h2 class="fw-bold display-5 text-light">  Safeguard Your Future Legacy With Just 1 Click!</h2>
                                </div>
                            </div>
                            <div class="lc-block mb-3">
                                <div editable="rich">
                                    <h4 class="fw-bold display-10 text-light">#aapkivirasat</h4>
                                </div>
                            </div>

                            <div class="lc-block mb-3">
                                <div editable="rich">
                                    <p class="lead text-light">DigiWill is a safe, simple, and secure way to discover all your financial and non-financial assets,  making you leave all the future worries behind.
                                    </p>
                                </div>
                            </div>

                            <div class="lc-block d-grid gap-2 d-md-flex justify-content-md-start">
                                <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank"><img src='https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png' style={{ width: "170px", objectFit: "contain" }}></img></a>

                            </div>

                        </div>
                    </div>

                    {/* extra heading section  */}
                    <div class="row justify-content-center text-center">

                        <div class="col-xl-8 col-lg-8 col-md-10">
                            <div class="lc-block mb-5 mt-5">
                                <div className='rich'>
                                    <h2 class="rfs-25 rfs-heading display-6 fw-bolder mb-4">One-stop solution for building your family's financial future!</h2>
                                </div>
                                <div className='rich'>
                                    <h3 class="rfs-25 text-light lh-1">No Retainers!</h3>
                                </div>
                                <div className='rich'>
                                    <h3 class="rfs-25 text-light lh-lg">No Human Dependency!</h3>
                                </div>
                                <div className='rich'>
                                    <h3 class="rfs-25 text-light">Don’t make your investments wait!</h3>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* section 2 start */}
                    <div class="row d-flex flex-lg-row align-items-center g-5 py-5">
                        <div class=" col-lg-6">
                            <div class="lc-block  d-flex justify-content-center ">
                                <img class="img-fluid" alt="Photo by Anders Jildén" src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/asset2.png" />
                            </div>
                        </div>
                        <div class="col-10 col-sm-8 col-lg-6 text-end" >
                            <div class="lc-block ic-bk-small-img  mb-2">
                                <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/asset+add.png'></img>
                            </div>
                            {/* <!-- /lc-block --> */}
                            <div class="lc-block mb-3 ">
                                <div editable="rich">
                                    <h2 className='text-light fw-bold display-5'>Hassle-Free Addition Of Assets</h2>
                                    <p class="lead text-light">We have an affordable pricing system for you to secure the financial future of your loved ones by simplifying your asset management process.</p>
                                </div>
                            </div>

                            {/* <!-- /lc-block --> */}
                            <div class="lc-block d-grid gap-2 d-md-flex justify-content-end">
                                <a class="btn btn-primary px-4 me-md-2 cta-landing-button" href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" role="button">Add Assets Seamlessly</a>

                            </div>
                        </div>
                    </div>

                    {/* section 3 start */}
                    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div class="col-10 col-sm-8 col-lg-6">
                            <img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/nominee.png" class="d-block mx-lg-auto img-fluid" alt="" loading="lazy" />
                        </div>
                        <div class="col-lg-6">
                            <div class="lc-block ic-bk-small-img  mb-2">
                                <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/nominee+add.png'></img>
                            </div>
                            <div class="lc-block mb-3">
                                <div editable="rich">
                                    <h2 class="fw-bold display-5 text-light">Best-In-Class Nominee Assignment Services</h2>
                                </div>
                            </div>

                            <div class="lc-block mb-3">
                                <div editable="rich">
                                    <p class="lead text-light">When it comes to inheritance, the crucial step is assigning nominees. We hand-hold you through the entire process so that they can easily claim what is rightfully theirs.
                                    </p>
                                </div>
                            </div>

                            <div class="lc-block d-grid gap-2 d-md-flex justify-content-md-start">
                                <a class="btn btn-primary px-4 me-md-2 cta-landing-button" href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" role="button">Get Started with DigiWill Now</a>
                            </div>


                        </div>
                    </div>

                    {/* section 4 start */}
                    <div class="row  align-items-center g-5 py-5">
                        <div class="col-10 col-sm-8 col-lg-6">
                            <img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/distribution.png" class="d-block mx-lg-auto img-fluid" alt="" loading="lazy" />
                        </div>
                        <div class="col-lg-6 text-end">
                            <div class="lc-block ic-bk-small-img mb-2">
                                <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/distribute.png'></img>
                            </div>
                            <div class="lc-block mb-3">
                                <div editable="rich">
                                    <h2 class="fw-bold display-5 text-light">Indisputable Allocation of Assets</h2>
                                </div>
                            </div>

                            <div class="lc-block mb-3">
                                <div editable="rich">
                                    <p class="lead text-light">When the right time comes, we ensure that your assigned nominees receive the assets you have always desired. No mess. No stress!
                                    </p>
                                </div>
                            </div>

                            <div class="lc-block d-grid gap-2 d-md-flex justify-content-md-end">
                                <a class="btn btn-primary px-4 me-md-2 cta-landing-button" href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" role="button"> Learn More with DigiWill </a>
                            </div>

                        </div>
                    </div>

                    {/* section 5 start */}
                    <div class="row d-flex flex-lg-row-reverse align-items-center g-5 py-5">
                        <div class=" col-lg-6">
                            <div class="lc-block d-flex justify-content-center">
                                <img class="img-fluid" alt="Photo by Anders Jildén" src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/afteryou.png" />
                            </div>
                        </div>
                        <div class="col-10 col-sm-8 col-lg-6 " >
                            <div class="lc-block ic-bk-small-img  mb-2">
                                <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/communicate.png'></img>
                            </div>
                            {/* <!-- /lc-block --> */}
                            <div class="lc-block mb-3 ">
                                <div editable="rich">
                                    <h2 className='fw-bold display-5 text-light'>We Communicate When Words Fall Short</h2>
                                    <p class="lead text-light">In times of distress, we make sure that nothing important gets missed. We fill the void after you, and become your voice when it matters the most!</p>
                                </div>
                            </div>
                            <div class="lc-block d-grid gap-2 d-md-flex justify-content-md-start">
                                <a class="btn btn-primary px-4 me-md-2 cta-landing-button" href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" role="button"> Subscribe to DigiWill Now  </a>
                            </div>

                            {/* <!-- /lc-block --> */}
                        </div>
                    </div>

                    {/* section 6 start */}
                    <div class="row flex-lg-row align-items-center py-3 mt-3 mb-3">
                        <div class="col-10 col-sm-8 col-lg-6">
                            <img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/expresswill.png" class="d-block mx-lg-auto img-fluid" alt="" loading="lazy" />
                        </div>
                        <div class="col-lg-6 text-end">
                            <div class="lc-block ic-bk-small-img mb-2">
                                <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/express+will.png'></img>
                            </div>
                            <div class="lc-block mb-3">

                                <div editable="rich">
                                    <h2 class="fw-bold display-5 text-light">Ready Your Will Before It’s Too Late</h2>
                                </div>
                            </div>

                            <div class="lc-block mb-3">
                                <div editable="rich">
                                    <p class="lead text-light">Time is money. It is more than that when it comes to will creation. Our hi-tech app ensures that you are onboarded in only 3-easy steps effortlessly.
                                    </p>
                                </div>
                            </div>


                            <div class="lc-block d-grid gap-2 d-md-flex justify-content-md-end">
                                <a class="btn btn-primary px-4 me-md-2 cta-landing-button" href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" role="button">Try Our 3-Steps Virasat Setup Now </a>
                            </div>

                        </div>
                    </div>

                    {/* cta form */}
                    <div className=' ' id='app-form-section'>
                        <section class="darkBG ">
                            <div className='container'>
                                <div className='d-flex flex-row justify-content-center '>
                                    <h3 className='text-light mt-4 display-8 '>Secure yourself and your loved ones now</h3>
                                </div>

                                <div className='app-form-wrapper'>
                                    <form className="d-flex flex-row justify-content-around px-5 py-4 gap-3" onSubmit={(e) => handleRegister(e)}>
                                        <input type="text" required pattern="^[A-Za-z -]+$" value={registerData.name} onChange={(e) => handleChange(e)} name='name' class="form-control form-input-app" placeholder="Your Name" />
                                        <input type="email" required value={registerData.email} onChange={(e) => handleChange(e)} name='email' class="form-control form-input-app" placeholder="Your Email" />
                                        {/* <ReCAPTCHA
                                            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                            onChange={onChange}

                                        /> */}
                                        <div className="captcha" style={{ transform: "scale(0.80)", transformOrigin: "60px 0" }}>
                                            <ReCAPTCHA
                                                sitekey="6LfPk6MjAAAAAILoHwcOKf-55xEIYFxc9tyWQzsw"
                                                onChange={onChange}
                                            />
                                        </div>
                                        <button type="submit" class="btn btn-primary app-form-btn" disabled={!verifed}>Subscribe Now</button>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>


                {/* section mobile view */}

                <div class="row" id='mobile-view'>
                    <div class="col-lg-12 col-12 justify-content-center text-center">
                        <div class="lc-block mb-5">
                            <div editable="rich ">
                                <h4 className='text-light display-4'>Aapki Virasat Aapke Apno Ke Liye</h4>
                            </div>
                        </div>
                        <div class="lc-block mb-4">
                            <a class="mobile-img" href="#"><img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/App-home.png' /></a>
                        </div>

                    </div>

                    <div className='d-flex flex-sm-row justify-content-center align-items-center cx-mb-main-wrapper mb-2'>
                        <div class="lc-block cx-mb-wrapper ">
                            <div className='d-flex flex-row flex-sm-wrap'>
                                <div className='d-flex flex-row align-items-center cx-mb-contain'>
                                    <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/1click.png" alt="" />
                                    <span className='text-light'>1-Click Asset Discovery</span>
                                </div>
                            </div>
                        </div>
                        <div class="lc-block cx-mb-wrapper ">
                            <div className='d-flex flex-row flex-sm-wrap'>
                                <div className='d-flex flex-row align-items-center cx-mb-contain'>
                                    <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/nomination.png" alt="" />
                                    <span className='text-light '>Nomination Across Assets</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='d-flex flex-sm-row justify-content-center align-items-center cx-mb-main-wrapper mb-2'>
                        <div class="lc-block cx-mb-wrapper">
                            <div className='d-flex flex-row flex-sm-wrap'>
                                <div className='d-flex flex-row align-items-center cx-mb-contain'>
                                    <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/wt.png" alt="" />
                                    <span className='text-light'>Automated Wealth Transfer</span>
                                </div>
                            </div>
                        </div>
                        <div class="lc-block cx-mb-wrapper ">
                            <div className='d-flex flex-row flex-sm-wrap'>
                                <div className='d-flex flex-row align-items-center cx-mb-contain'>
                                    <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/security.png" alt="" />
                                    <span className='text-light'>AES 256-Bit Encryption + Tokenization</span>
                                </div>
                            </div>
                        </div>

                    </div>


                    {/* <div class="lc-block d-grid gap-2 d-md-flex justify-content-md-start mb-4 mt-5" >
                        <a class="btn btn-primary px-4 me-md-2" href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" role="button">Download DigiWill Now!</a>
                    </div> */}
                     <div class="lc-block d-grid gap-2 d-md-flex justify-content-md-start justify-content-center mt-4 mb-4">
                                <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank"><img src='https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png' style={{ width: "230px", objectFit: "contain" }}></img></a>

                            </div>
                    <div class="lc-block mb-4">
                        <div className='text-center'>
                            <h4 className='text-light'> Our Partner</h4>
                        </div>
                    </div>

                    <div class="lc-block mb-5  " >
                        <div className='rich d-flex flex-row justify-content-center flex-wrap cx-partner-logo'>
                            <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/razarpay2.png' style={{ width: "100px", objectFit: "contain" }}></img>
                            <img src='https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/aws.png' style={{ width: "100px", objectFit: "contain" }}></img>
                            <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/fego.png' style={{ width: "100px", objectFit: "contain" }}></img>
                            <img src='https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/surepass.png' style={{ width: "100px", objectFit: "contain" }}></img>
                            <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/Equifax.png' style={{ width: "100px", objectFit: "contain" }}></img>
                            <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/Edelweiss.png' style={{ width: "100px", objectFit: "contain" }}></img>
                        </div>
                    </div>
                    <div class="lc-block mb-4" >
                        <div className='text-center'>
                            <p className='btn-footer '>Crafted with Love in India</p>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default PageSectionOne
