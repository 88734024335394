import React, { useState, useEffect } from 'react';

import { BASE_URL } from './urls';


function Partnericons() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>

            <section id="cx-partnericon" className=''>
                <div className="container-fluid">
                    <div className="cx-outer-partnericon">
                        <div className="cx-content-header cx-partner-header">
                            <h2 className='text-body'>Our <span class="blink_me">Partners:</span></h2>
                        </div>
                        <br></br>
                        <div className='container-fluid'>
                            <div className='partner-icon-wrapper'>
                                <div className='partner-icons-images'>
                                    <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/Dastawezz.png' alt='Dastawezz' />
                                    <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/Goalteller.png' alt=' GoalTeller' />
                                    <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/Ditto.png' alt=' Ditto' className='img3' />
                                    <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/Equifax.png' alt='Equifax' />
                                    <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/fego.png' alt='fego' className='img5' />
                                    <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/Edelweiss.png' alt=' Edelweiss' className='img6'/>
                                    <img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/surepassblack.png' alt=' surepass' className='img6'/>    
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            

        </div>
    )
}

export default Partnericons;