
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

const B2bEmpStatus = () => {
    useEffect(() => {
        $(document).ready(function () {
            $('#EmpDataStatusList').DataTable();
        });
    });
    return (
        <>
            <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
                {/* <!-- Navbar Brand--> */}
                <a class="navbar-brand ps-3" href="index.html"><img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/whitee.png" alt="" style={{ width: "70px" }} /></a>
                {/* <!-- Sidebar Toggle--> */}
                <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i class="fas fa-bars"></i></button>
                {/* <!-- Navbar Search--> */}
                <form class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                    <div class="input-group">
                        <input class="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                        <button class="btn btn-primary" id="btnNavbarSearch" type="button"><i class="fas fa-search"></i></button>
                    </div>
                </form>
                {/* <!-- Navbar--> */}
                <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#!">Settings</a></li>
                            <li><a class="dropdown-item" href="#!">Activity Log</a></li>
                            <li><hr class="dropdown-divider" /></li>
                            <li><a class="dropdown-item" href="#!">Logout</a></li>
                        </ul>
                    </li>
                </ul>
            </nav>
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                        <div class="sb-sidenav-menu">
                            <div class="nav">
                                <div class="sb-sidenav-menu-heading">Core</div>
                                <a class="nav-link" href="index.html">
                                    <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                    Dashboard
                                </a>
                                <div class="sb-sidenav-menu-heading">Interface</div>
                                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                                    Layouts
                                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                </a>
                                <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav class="sb-sidenav-menu-nested nav">
                                        <a class="nav-link" href="layout-static.html">Static Navigation</a>
                                        <a class="nav-link" href="layout-sidenav-light.html">Light Sidenav</a>
                                    </nav>
                                </div>
                                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                                    <div class="sb-nav-link-icon"><i class="fas fa-book-open"></i></div>
                                    Pages
                                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                </a>
                                <div class="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                                    <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                                        <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">
                                            Authentication
                                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                        </a>
                                        <div class="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                            <nav class="sb-sidenav-menu-nested nav">
                                                <a class="nav-link" href="login.html">Login</a>
                                                <a class="nav-link" href="register.html">Register</a>
                                                <a class="nav-link" href="password.html">Forgot Password</a>
                                            </nav>
                                        </div>
                                        <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseError" aria-expanded="false" aria-controls="pagesCollapseError">
                                            Error
                                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                        </a>
                                        <div class="collapse" id="pagesCollapseError" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                            <nav class="sb-sidenav-menu-nested nav">
                                                <a class="nav-link" href="401.html">401 Page</a>
                                                <a class="nav-link" href="404.html">404 Page</a>
                                                <a class="nav-link" href="500.html">500 Page</a>
                                            </nav>
                                        </div>
                                    </nav>
                                </div>
                                <div class="sb-sidenav-menu-heading">Addons</div>
                                <a class="nav-link" href="charts.html">
                                    <div class="sb-nav-link-icon"><i class="fas fa-chart-area"></i></div>
                                    Charts
                                </a>
                                <a class="nav-link" href="tables.html">
                                    <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
                                    Tables
                                </a>
                            </div>
                        </div>
                        <div class="sb-sidenav-footer">
                            <div class="small">Logged in as:</div>
                            Start Bootstrap
                        </div>
                    </nav>
                </div>
                <div id="layoutSidenav_content">
                    <main>
                        <div className='row px-3'>
                            <div className='col-lg-12 col-12'>
                                <div class="container-fluid p-0 ">
                                    <h4 class="mt-4">Employee Status Data</h4>
                                    <div class="card mb-4">
                                        <div class="card-header">
                                            <i class="fas fa-table me-1"></i>
                                            Data Deatils
                                        </div>
                                        <div class="card-body">
                                            <table id="EmpDataStatusList">
                                                <thead>
                                                    <tr>

                                                        <th>Employee Name</th>
                                                        <th>Employee ID</th>
                                                        <th>Mobile Number</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>

                                                    </tr>
                                                </thead>
                                                <tfoot>

                                                </tfoot>

                                                <tbody>
                                                    <tr>
                                                        <td>Herrod Chandler</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td className='text-danger'>N/A</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>Tiger Nixon</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td>Eligible</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Airi Satou</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td className='text-danger'>N/A</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>TCedric Kelly</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td>Eligible</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ashton Cox</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td className='text-danger'>N/A</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Garrett Winters</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td className='text-danger'>N/A</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Airi Satou</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td className='text-danger'>N/A</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>TCedric Kelly</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td>Eligible</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ashton Cox</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td className='text-danger'>N/A</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Garrett Winters</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td className='text-danger'>N/A</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Airi Satou</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td className='text-danger'>N/A</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>TCedric Kelly</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td>Eligible</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ashton Cox</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td className='text-danger'>N/A</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Garrett Winters</td>
                                                        <td>023456</td>
                                                        <td>91234567890</td>
                                                        <td className='text-danger'>N/A</td>
                                                        <td>
                                                            <button className='btn btn-success'>Action</button>
                                                        </td>
                                                    </tr>


                                                </tbody>

                                            </table>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                </div>
            </div>
        </>
    )
}

export default B2bEmpStatus
