import React, { useState } from 'react';
import { PLAYSTORE_URL, TRACKING_CODE } from './pages/urls';
import ReactGA from 'react-ga';
ReactGA.initialize(TRACKING_CODE);

function Popper() {

  const [show, setShow] = useState('')
  const handlepopper = () => {
    setShow('none')
  }

  const handleclick = () => {
    ReactGA.event({
      category: 'User',
      action: 'user clicked on install button of popper..'
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.location.href = PLAYSTORE_URL
  }

  return (
    <div style={{position: "sticky",top:"0", zIndex: "99999999999999999999"}}>
      <div className="cx-install" style={{ display: show, alignItems: 'center' }}>
        <div className="cx-inlogo d-flex align-items-center">
          <button onClick={() => { handlepopper() }} type="button"><i className='bx bx-x'></i></button>
          {/* <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/whitee.png"  style={{height:55,width:70}} /> */}
        </div>
        <span>
          Launch Offer - Flat 50% OFF for All Users 
        </span>
        <button onClick={() => handleclick()} type="button" className="cx-inbtn"><span>Install</span><i className='bx bxs-download' ></i></button>
      </div>

    </div>


  )
}

export default Popper;
