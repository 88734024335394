
import React, { useState, useEffect } from 'react';
import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar'
import { PLAYSTORE_URL } from './urls';
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
import MetaTags from 'react-meta-tags';
ReactGA.initialize(TRACKING_CODE);
function Invest() {
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(

        () => {
            setProgress(100)
        }, []
    )
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited Invest page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = "Invest - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])
    return (<div>
        {/* <MetaTags>
            <title> Invest in | A One-Stop Financial Market Place - DigiWill</title>
            <meta name="description" content="Invest, record, grow, and transmit all your finances all at one place on the go with Invest in Feature from DigiWill." />
        </MetaTags> */}

        {/* 
          <Popper></Popper> */}

        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />

        <section id="cx-why" className='' >
            <div className="container">
                <div className="cx-outer-seured">
                    <div className="cx-content-header seq-sec "  >
                        {/* <h2 className='mb-2 ' style={{color:"#0CFEBC"}} data-aos="zoom-in-right" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="300">Secure By Default</h2> */}
                        <h2 className='text-body'data-aos="zoom-in-right" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="100"><span className='blink_me'>Secure By Default</span></h2>
                        <p className='fs-5 text-light'>We are proud to say that we don’t need to harvest your data to make money!</p>
                    </div>

                </div>
                {/* <section id="cx-outer-besqured" className='' >
                    <div className="container cx-outer-besequred">

                        <div class="row row-cols-1 row-cols-md-3 g-4">
                            <div class="col">
                                <div class="card cx-card-besequred">
                                    <div class="card-body">
                                        <img src="./images/sequre/card1.png" />
                                        <h5 class="card-title">Facial Recognisation Security</h5>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card cx-card-besequred">

                                    <div class="card-body">
                                        <img src="./images/sequre/card2.png" />
                                        <h5 class="card-title">Aadhar E-Sign & Stamping</h5>
                                       
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card cx-card-besequred">

                                    <div class="card-body">
                                        <img src="./images/sequre/card3.png" />
                                        <h5 class="card-title">Tokenization + AES 256 Bit Encryption</h5>
                                      
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </section> */}
            </div>
        </section>


    </div>);
}

export default Invest;
