import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar'
import { BASE_URLS, PLAYSTORE_URL } from './urls';
import FooterEnsurance from './FooterEnsurance';
import { positions } from 'react-alert'
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
import { useAlert } from 'react-alert'
import Modal from 'react-modal';
import Testimonials from './Testimonials';
import YoutubeVideos from './YoutubeVideos';
import 'animate.css';
import AOS from 'aos';
import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Typewriter from 'typewriter-effect';
import MetaTags from 'react-meta-tags';
import Partnerslogo from './Partnerslogo';
import Partnericons from './Partnericons';
import PriceVideo from './PriceVideo';
import VideoSection from './VideoSection';
import HowWorkStep from './HowWorkStep';
import FeaturesVideo from './FeaturesVideo';
import BlogHome from './BlogHome';
import FaqHome from './FaqHome';
import { AppContext } from '../../user/AppContext';
import HorizontalLinearStepper from './HorizontalLinearStepper'
import MyStepper from './MyStepper'
import ModalVideo from 'react-modal-video';
import { Stepper } from '@mui/material';
import Beprepared from './Beprepared';
import BePreparedNew from './BePreparedNew';
import BeSequred from './BeSequred';
import JoinRevolation from './JoinRevolation';
import DistributionInfo from './DistributionInfo';
import HowWork2 from './HowWork2'
import PriceVideoNew from './PriceVideoNew'
import WhyDemo from './WhyDemo';

// const customStyles = {
//     content: {
//         top: '55%',
//         left: '50%',
//         right: 'auto',
//         bottom: 'auto',
//         marginRight: '-40%',
//         transform: 'translate(-50%, -50%)',
//         backgroundColor: 'white',
//         zindex: '234493940303033393939',
//         overflow: 'hidden',
//         border: '1px solid #05A081',
//         borderRadius: '20px'
//     },
// };

ReactGA.initialize(TRACKING_CODE);

const customStyles1 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        border: 'none'

    },
};

function Home() {
    const [progress, setProgress] = useState(0)
    const [contactData, setContactData] = useState({ name: '', email: "", phone_no: "", city: "" })
    const alert = useAlert()
    const [verifed, setVerifed] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerifed(true);
    }
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext)

    let subtitle1;
    const [modalIsOpen1, setIsOpen1] = React.useState(false);

    function openModal1() {
        setIsOpen1(true);
    }

    function afterOpenModal1() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal1() {
        setIsOpen1(false);
    }

    AOS.init({
        disable: function () {
            var maxWidth = 800;
            return window.innerWidth < maxWidth;
        }
    });
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);


    useEffect(

        () => {
            setProgress(100)
            setTimeout(() => {
                setIsOpen(true)
            }, 3000)
        }, []
    )
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited Home page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        // document.title = 'One-Stop Solution to Record Your Investments & Assign Nominees - DigiWill';

    }, [])

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);

    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [colOpen, setcolOpen] = React.useState(false);
    function opencol() {
        setcolOpen(true);
    }
    function closeCol() {
        setcolOpen(false);
    }


    /* life status data tracker start */

    async function handleContactRegister(e) {
        e.preventDefault();

        const { name, email, mobile_no, city } = e.target;
        const data = new FormData();

        data.append('name', name.value);
        data.append('email', email.value);
        data.append("mobile_no", mobile_no.value);
        data.append("city", city.value);

        console.log(data);
        const response = await fetch(`${BASE_URLS}app-launching-soon`, {
            method: "POST",
            body: data,
        });
        const newdata = await response.json();
        console.log(newdata);

        setContactData({ name: '', email: "", mobile_no: "", city: "" });
        setTimeout(() => {
            setProgress(25);
        }, 100);
        setTimeout(() => {
            setProgress(70);
        }, 100);
        setTimeout(() => {
            setProgress(100);
        }, 100);

        setIsOpen(false);
        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER });

    }
    const [isOpen, setOpen] = useState(false)

    const handleChange = (e) => {

        if (e.target.name === 'name') {

            setContactData({ ...contactData, name: e.target.value })
        }
        if (e.target.name === 'email') {
            setContactData({ ...contactData, email: e.target.value })
        }
        if (e.target.name === 'mobile_no') {
            setContactData({ ...contactData, mobile_no: e.target.value })
        }
        if (e.target.name === 'city') {
            setContactData({ ...contactData, city: e.target.value })
        }
    }
    const [buttonText, setButtonText] = useState('Demo Video');
    function handleClick() {
        setButtonText('Close');
    }

    return (
        <div>
            <MetaTags>
                <title>DigiWill - Aapki Virasat Aapke Apno Ke Liye | Virasat Online</title>
                <meta name="description" content="Create your Online Virasat before it's too late. With DigiWill you can add assets, nominate and distribute within clicks." />
            </MetaTags>
            {/* <Popper></Popper> */}


            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            {/* <div >
                {/* <button onClick={openModal}>Open Modal</button>   
                <Modal

                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <i onClick={closeModal} className='bx bxs-x-circle float-end fs-1'></i>
                    <div className="row align-items-center ">
                        <div className="col-md-6 d-flex flex-column align-items-center">
                            <Link to='/'> <img style={{ marginBottom: 20, width: "100px" }} src="./images/home/finalogo.png" /></Link>
                            <div className="cx-contact-content-left">

                                <img src="images/home/how is work/step2.png" />

                                <h6>info@yourDigiWill.com</h6>

                            </div>
                        </div>
                        <div className="col-md-6 col-sm-4">
                            <div className="cx-contact-content">
                                <h2 className="cx-cont">Join the waiting list</h2>
                                <p>Fill in the below form and we’ll reach out to you</p>
                                <form onSubmit={(e) => handleContactRegister(e)}   >

                                    <div className="mb-3">
                                        <input type="text" maxlength="20" pattern="^[A-Za-z -]+$" required name="name" value={contactData.name} onChange={(e) => handleChange(e)} className="form-control" id="exampleInputEmail1" placeholder="Name" />
                                    </div>
                                    <div className="mb-3">
                                        <input type="email" required size="30" name="email" value={contactData.email} onChange={(e) => handleChange(e)} className="form-control" id="exampleInputEmail1" placeholder="Email" />
                                    </div>
                                    <div className="mb-3">
                                        <input className="form-control" required type="tel" pattern="[0-9]{10}" name="mobile_no" value={contactData.mobile_no} onChange={(e) => handleChange(e)} placeholder="phone" />
                                    </div>
                                    <div className="mb-3">
                                        <input className="form-control" pattern="[A-Za-z]*" type="text" minlength="1" maxlength="20" size="20" required name="city" value={contactData.city} onChange={(e) => handleChange(e)} placeholder="city" />
                                    </div>
                                    <div className="mb-3">
                                        <div className="captcha" style={{ transform: "scale(0.80)", transformOrigin: "0 0" }}>
                                            <ReCAPTCHA
                                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="cx-contact-grp">
                                        <button type="submit" className="cx-contact-btn" disabled={!verifed}>Send</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                </Modal>
            </div> */}

            <main style={{ overflow: "hidden" }}>
                {/* section one banner and video info */}
                <section id="cx-banner" className=' '>
                    <div className="container">
                        <div className="cx-banner-outer">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6 ">
                                    <div className="cx-banner-content">
                                        <div className='d-flex flex-row flex-wrap justify-content-between mb-4'>
                                            {/* <img className='cx-banner-left-img' src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/Jayte2.png" alt="Satyamev Jayate" />  */}
                                            <img className='cx-banner-left-img' src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/startup.png" alt="Startup India" />
                                            <img className='cx-banner-left-img aws-img' src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/Aws+logo.png" alt="Amazon AWS" />
                                            <img className='cx-banner-left-img' src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/digiLocker.png" alt="Digi Locker" />
                                        </div>
                                        {/* <h2 className='mt-2 mb-3'>Automatically transmit all your wealth to your loved ones after you.</h2> */}

                                        <h2 className='mt-2 mb-3'>Ai Powered Wealth Transfer<br /> To Your Loved Ones <br /> <span className='text-success'>"After You."</span> </h2>
                                        <p className='fw-bold mb-4'>Discover, Nominate, Distribute & Share your Financial & Non-Financial Assets with your Loved Ones in 3 Clicks.
                                        </p>

                                        <div className="cx-banner-buttons d-flex">
                                            <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                            {/* <button className="cx-banner-btns"><img src="images/home/app_store.png" alt="google_store" /></button>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="cx-banner-content d-flex ">
                                        <video width={600}
                                            src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/web+video+28th+nov+2022_1.mp4"
                                            muted
                                            autoPlay={"autoplay"}
                                            preload="auto"
                                            loop
                                            title='DigiWill steps'
                                        ></video>

                                    </div>
                                </div>
                               <div className='text-center mt-4 product-wrapper'>
                                    <div className='d-flex flex-row justify-content-center'>
                                        <button onClick={openModal1} className='cx-btn-youtube rounded-pill d-flex fs-7' href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                            Product video
                                            <i class="fa fa-play fs-4 ms-2"></i>
                                        </button>
                                    </div>


                                    <Modal
                                        isOpen={modalIsOpen1}
                                        onAfterOpen={afterOpenModal1}
                                        onRequestClose={closeModal1}
                                        style={customStyles1}
                                        contentLabel="Example Modal"
                                    >
                                        <h2 ref={(_subtitle1) => (subtitle1 = _subtitle1)}></h2>
                                        <div className='d-flex flex-row justify-content-center product' >
                                            <div className='youtube-wp'>
                                            <iframe width="853" height="480" src="https://www.youtube.com/embed/t3bCdU07UY4" title="Financial & Non-Financial Asset Inheritance Solution | Aapki Virasat Aapke Apno Ke Liye - DigiWill" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>  
                            </div>
                        </div>
                    </div>
                </section>

                <VideoSection></VideoSection>
               
                <FeaturesVideo></FeaturesVideo>
                <HowWork2></HowWork2>
                {/* <MyStepper /> */}
                {/* <BePreparedCard></BePreparedCard> */}
                {/* <PriceVideo></PriceVideo> */}

                {/* <Beprepared />
                <BePreparedNew></BePreparedNew> */}
                <PriceVideoNew></PriceVideoNew>
                {/* <BeSequred /> */}
               
                <Partnerslogo></Partnerslogo>
                <JoinRevolation />
                {/* <WhyDemo></WhyDemo> */}

                <HowWorkStep></HowWorkStep>
                <DistributionInfo />
                <Partnericons></Partnericons>



                {/* <section id="cx-offers" className=''>
                    <div className="container">
                        <div className="cx-outer">
                            <div className="cx-content-header" >
                                <h2>What we offer</h2>
                            
                                <p className='text-center'> <Typewriter
                                    options={{
                                        strings: ['Secure the financial future of your loved ones.'],
                                        autoStart: true,
                                        loop: true,
                                        pauseFor: 600000,
                                    }}
                                />
                                </p>
                            </div>
                            <div className="row" >
                                <div className="col-lg-3 col-md-6 cx-col" >
                                    <div className="cx-content cx-content-off">
                                        <img src="images/home/what section logo/Curate.png" alt="Curate" className='animate__animated animate__pulse animate__infinite	infinite' />
                                        <h4>Curate</h4>
                                        <p>We have curated DigiWill App for you to create financial records of all your insurance policies, mutual funds, shares, crypto-currencies, cash/gold/silver reserves, and all other assets and liabilities..</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 cx-col" >
                                    <div className="cx-content cx-content-off">
                                        <img src="images/home/what section logo/Communication.png" alt="Communicate" className='animate__animated animate__pulse animate__infinite	infinite' />
                                        <h4>Communicate</h4>
                                        <p>We assure Communication of your financial records back to your family,smoothly.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 cx-col" >
                                    <div className="cx-content cx-content-off">
                                        <img src="images/home/what section logo/Claim.png" alt="Claim-Assistance" className='animate__animated animate__pulse animate__infinite	infinite' />
                                        <h4>Claim Assistance</h4>
                                        <p>We stand strong and fight for you and your loved ones to ensure that your loved ones claim every asset of yours.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 cx-col" >
                                    <div className="cx-content cx-content-off">
                                        <img src="images/home/what section logo/Reward.png" alt="Reward" className='animate__animated animate__pulse animate__infinite	infinite' />
                                        <h4>Reward</h4>
                                        <p>We reward you with specially crafted DigiCoins, for taking the right steps towards securing your financial inheritance and helping curb the rise of unclaimed money in our country.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}




                {/* <section id="cx-why" className='' >
                    <div className="container">
                        <div className="cx-outer">
                            <div className="cx-content-header">
                                <h2 className='mb-5'>Why Choose Us</h2>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="cx-content cx-content-why">
                                        <ul className="cx-list">

                                            <li>25000+ Families trust DigiWill for Asset Claiming Assistance
                                                <img src="./images/home/bullet_tick.svg" alt="bullet-tick" />
                                            </li>
                                            <li>11 Strategic partners to cater to our users.
                                                <img src="./images/home/bullet_tick.svg" alt="bullet-tick" />
                                            </li>
                                            <li>Backed by AWS Activate
                                                <img src="./images/home/bullet_tick.svg" alt="bullet-tick" />
                                            </li>
                                            <li>We make your Will known to your loved ones after your passing.
                                                <img src="./images/home/bullet_tick.svg" alt="bullet-tick" />
                                            </li>
                                            <li>You can create financial records in audio/video/document formats with just one click on our App.
                                                <img src="./images/home/bullet_tick.svg" alt="bullet-tick" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="cx-content cx-content-why d-flex justify-content-center">
                                        <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/activate.png" alt="aws-activate" style={{ width: "100%", height: "100%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}




                <BlogHome></BlogHome>

                {/* < YoutubeVideos></YoutubeVideos> */}

                <Testimonials></Testimonials>
                <FaqHome></FaqHome>
                <FooterEnsurance />

            </main>

        </div>
    )
}

export default Home;
