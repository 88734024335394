import React, { useState, useEffect } from 'react'

import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar';
import MetaTags from 'react-meta-tags';
import { simpleGetCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';

function Terms() {

    const [progress, setProgress] = useState(0)
    const [content,setcontent]=useState("")

    function createMarkup(data) {
        return { __html: data };
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(

        () => {
            setProgress(100)
            document.title = "Terms and Conditions - DigiWill-Keep The Track Record of Your Assets and Liabilities"
        }, []
    )
   


    useEffect(()=>{


        simpleGetCallWithErrorResponse(ApiConfig.TERMS).then((data) => {
            setcontent(data.json)

        })
            .catch((error) => {
                console.log("api response", error);
            });
    },[])


    return (<div>
        <MetaTags>
            <title>Terms & Conditions | Description of DigiWill Services</title>
            <meta name="description" content=" Checkout DigiWill’s description of services, terms of use, privacy, confidentiality & use and protection of intellectual property rights." />
        </MetaTags>

      
        {/* <Popper></Popper> */}
      

        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />
        <section className="cxle-banner">
            <div className="container">
                <div clas="row " style={{ height: "100%" }}>
                    <div className="col-12 d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                        <div className="cxle-banner-content">
                            <span>Terms & Conditions</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='cxle-terms' className='container'>
        {
    content ? 
    <div>
   <p dangerouslySetInnerHTML={createMarkup(content)} /> 
    </div>

    :""
}
        </section>

    </div>
    )
}

export default Terms