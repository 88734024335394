import React, { useEffect, useState,useContext } from 'react';

// import Popper from '../Popper';
import { BASE_URL, PLAYSTORE_URL } from './urls';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import ReactGA, { set } from 'react-ga';
import { TRACKING_CODE } from './urls';
import LoadingBar from 'react-top-loading-bar';
import FooterEnsurance from './FooterEnsurance';
import MetaTags from 'react-meta-tags';
import { AppContext } from '../../user/AppContext';
function DeathPage() {
    const [progress, setProgress] = useState(0)
    const [button1, setbutton1] = useState("Click here to Request call from us?");
    const [id, setid] = useState()
    const alert = useAlert()
    const [registerData, setRegisterData] = useState({ your_full_name: '', your_email: "", deceased_person_name: "", deceased_person_id: "", pass_date: "", message: "" })
    const width = window.innerWidth
    const {show,setShow}=useContext(AppContext)
    useEffect(() => {

        if (width <= 972) {
          setShow('none')
        }
        else{
          setShow('flex')
        }
    
      }, [])

    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited to death report page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = "death report page"
    }, [])

    useEffect(

        () => {
            setProgress(100)
        }, []
    )

    const handleRegister = async (e) => {
        e.preventDefault()
        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })

        const { your_full_name, your_email, deceased_person_name, deceased_person_id, pass_date, message } = e.target
        const data = new FormData();
        data.append('your_full_name', your_full_name.value)
        data.append('your_email', your_email.value)
        data.append("deceased_person_name", deceased_person_name.value)
        data.append("deceased_person_id", deceased_person_id.value)
        data.append("pass_date", pass_date.value)
        data.append('message', message.value)

        console.log(data);
        const response = await fetch(`${BASE_URL}#`, {
            method: "POST",
            body: data,
        })
        const newdata = await response.json()
        console.log(newdata);

        setRegisterData({ your_full_name: '', your_email: "", deceased_person_name: "", deceased_person_id: "", pass_date: "", message: "" })
        setTimeout(() => {
            setProgress(25)
        }, 100);
        setTimeout(() => {
            setProgress(70)
        }, 100);
        setTimeout(() => {
            setProgress(100)
        }, 100);
        ReactGA.event({
            category: 'User',
            action: 'death report page'
        });

    }

    const handleChange = (e) => {

        if (e.target.name === 'your_full_name') {

            setRegisterData({ ...registerData, your_full_name: e.target.value })
        }

        if (e.target.name === 'your_email') {

            setRegisterData({ ...registerData, your_email: e.target.value })
        }

        if (e.target.name === 'deceased_person_name') {

            setRegisterData({ ...registerData, deceased_person_name: e.target.value })
        }
        if (e.target.name === 'deceased_person_id') {

            setRegisterData({ ...registerData, deceased_person_id: e.target.value })
        }
        if (e.target.name === 'pass_date') {

            setRegisterData({ ...registerData, pass_date: e.target.value })
        }
        if (e.target.name === 'message') {

            setRegisterData({ ...registerData, message: e.target.value })
        }

    }

    const [showResults, setShowResults] = React.useState(false)
    const onClick = () => setShowResults(true)

    const [showResults1, setShowResults1] = React.useState(false)
    const onClick1 = () => setShowResults1(true)
    return <div>
        <MetaTags>
            <title></title>
            <meta name="description" content="" />

        </MetaTags>
       
        <section id="cx-death" className='cx-death-page' >
            <div className="container">
                <div className="cx-outer-death">
                    <div className="cx-death-header">
                        <h2 className='mb-5'></h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-5" >
                            <div className=" cx-death-left-wrapper ">
                                <div className='left-wrapper-text'>
                                    <div className='mb-4 wrapper-text-get'>
                                        <h3 className='text-light'>Get In Touch</h3>
                                        <p className='text-light mb-3'>We’ love to hear from you. Our friendly<br></br> team is always here to chat.</p>
                                    </div>
                                    <div className='mb-3'>
                                        <h4 className='text-light '><i class="fa fa-envelope-o me-3" aria-hidden="true"></i>Contact Us</h4>
                                        <p className='text-light'>Our friendly team is here to help.</p>
                                        <p className='text-light mb-3'>info@DigiWill.com</p>
                                    </div>
                                    <div className='mb-3'>
                                        <h4 className='text-light  '><i class="fa fa-map-marker me-3" aria-hidden="true"></i>Office</h4>
                                        <p className='text-light  '>Come say hello at our office HQ.</p>
                                        <p className='text-light mb-3'>100 Smith Street<br></br> Wadi Pune 40 IND</p>
                                    </div>
                                    <div className=''>
                                        <h4 className='text-light '><i class="fa fa-phone me-3" aria-hidden="true"></i>Phone</h4>
                                        <p className='text-light mb-2'>Mon-Fri from 8am to 5pm.</p>
                                        <p className='text-light'>+1 (555) 000-0000</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-7">
                            <div className=" cx-death-right-wrapper ">
                                <div className='cx-right-header'>
                                    <h2 className='mb-3 mt-3 '>Report Death</h2>
                                    <p>You can reach us anytime via <span>info@yourDigiWill.com</span> </p>
                                </div>
                                <div className='death-form'>
                                    <form onSubmit={(e) => handleRegister(e)}  >
                                        <div className="mb-3 ">
                                            <label for="exampleFormControlInput1" class="form-label ">Your Full Name</label>
                                            <input type="text" required value={registerData.your_full_name} onChange={(e) => handleChange(e)} name='your_full_name' className="form-control " id="exampleInputName" />
                                        </div>

                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">Your Email</label>
                                            <input type="email" required value={registerData.your_email} onChange={(e) => handleChange(e)} name='your_email' className="form-control" id="exampleInputEmail1" />
                                        </div>
                                        <div className='mb-3'>
                                            <label for="exampleFormControlInput1" class="form-label">Relation</label>
                                            <select class=" form-select  " aria-label="Default select example" >
                                                <option selected >Select Relationship</option>
                                                <option value="1">Father</option>
                                                <option value="2">Mother</option>
                                                <option value="3">Brother</option>
                                                <option value="3">Sister</option>
                                                <option value="3">Friend</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" class="form-label ">Full name of the deceased person</label>
                                            <input type="text" required value={registerData.deceased_person_name} onChange={(e) => handleChange(e)} name='deceased_person_name' className="form-control " id="exampleInputName" />
                                        </div>

                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" class="form-label ">DigiWill User ID of deceased person</label>
                                            <input type="text" required value={registerData.deceased_person_id} onChange={(e) => handleChange(e)} name='deceased_person_id' className="form-control " id="exampleInputName" />
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" class="form-label ">When did they pass away?</label>
                                            <input type="date" required value={registerData.pass_date} onChange={(e) => handleChange(e)} name='pass_date' className="form-control " id="exampleInputName" />
                                        </div>
                                        <div className='mb-3'>
                                            <h6>Please provide verification</h6>
                                            <p>Please provide a death certificate or other document verifying that you're an
                                                immediate family member or executor of the account holder</p>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onClick={onClick} />
                                                {showResults ? <div class="mb-3" id="div1" >
                                                    <label for="formFile" class="form-label">pan card</label>
                                                    <input class="form-control" type="file" id="formFile" />
                                                </div> : ''}
                                                <label class="form-check-label" for="inlineCheckbox1">Pan Card</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onClick={onClick1} />
                                                {showResults1 ? <div class="mb-3" id="div1" >
                                                    <label for="formFile" class="form-label">aadhar card</label>
                                                    <input class="form-control" type="file" id="formFile" />
                                                </div> : ''}
                                                <label class="form-check-label" for="inlineCheckbox1">Aadhaar card</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                <label class="form-check-label" for="inlineCheckbox1">Death Certificate</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                <label class="form-check-label" for="inlineCheckbox1">Other</label>
                                            </div>

                                        </div>
                                        <div class="mb-3" id="div1" >
                                                    <label for="formFile" class="form-label">defalut card</label>
                                                    <input class="form-control" type="file" id="formFile" />
                                                </div>
                                        <div class="mb-3">
                                            <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                                            <textarea type='text' name='message' class=" form-control " value={registerData.message} onChange={(e) => handleChange(e)} id="exampleFormControlTextarea1" rows="3"></textarea>
                                        </div>
                                        <div className='mb-4'>
                                            <button type="submit" className=" btn btn-success cx-death-btn">Send</button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </section>





    </div>;
}

export default DeathPage;
