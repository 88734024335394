import React, { useEffect, useState, useRef } from 'react';

import Popper from '../Popper';
import { BASE_URLS, PLAYSTORE_URL, TRACKING_CODE } from './urls';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import LoadingBar from 'react-top-loading-bar'
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags';
ReactGA.initialize(TRACKING_CODE);
function Thanku() {

    const [progress, setProgress] = useState(0)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (

        <div>
            {/* <MetaTags>
                <title>Become our Channel Partner | DigiWill</title>
                <meta name="description" content=" A unique opportunity to work with India’s First and Only Asset Claim Guaranteeing Company." />
            </MetaTags> */}


            {/* <Popper></Popper> */}

            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <section id="cxp-thank">
                <div className="cxp-hero-thank d-flex align-items-center">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12 cxp-thank-header">
                                <div>
                                    <h1>Thank you</h1>
                                </div>
                            </div>


                            <div className="col-lg-12 cxp-thank-box">

                                <div className="cxp-hero-content-thank">
                                    <h2>We appreciate your interest in<br></br> our claim assistance services.</h2>
                                    <p>We will get in touch with you within 24 hours.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="cxp-thank">
                <div className="cxp-hero-thank-header d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="cxp-hero-content-thank">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </div>);
}

export default Thanku;
