
import React, { useState, useEffect } from 'react';
import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar'
import { PLAYSTORE_URL } from './urls';
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
import MetaTags from 'react-meta-tags';
ReactGA.initialize(TRACKING_CODE);
function WhyDemo() {
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(

        () => {
            setProgress(100)
        }, []
    )
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited Invest page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = "Invest - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])
    return (<div>
        {/* <MetaTags>
            <title> Invest in | A One-Stop Financial Market Place - DigiWill</title>
            <meta name="description" content="Invest, record, grow, and transmit all your finances all at one place on the go with Invest in Feature from DigiWill." />
        </MetaTags> */}

        {/* 
          <Popper></Popper> */}

        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />
       
       <section id="cx-why" className='' >
                    <div className="container">
                        <div className="cx-outer">
                            <div className="cx-content-header">
                                <h2 className='mb-4'>Why Choose Us?(Demo)</h2>
                                
                            </div>
                            <div>
                                <img src='./images/demo section.png' style={{width:"100%"}}></img>
                            </div>
                        </div>
                       
                    </div>
                </section>


    </div>);
}

export default WhyDemo;
