import React,{useState,useEffect } from 'react';

import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar'
import { PLAYSTORE_URL, TRACKING_CODE } from './urls';
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags';
ReactGA.initialize(TRACKING_CODE);
function Tracker() {
    const [progress, setProgress] = useState(0)
    useEffect(

        ()=>{
            setProgress(100)
        },[]
    )
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(()=>{
        ReactGA.event({
            category: 'User',
            action: 'visited Family communication page'
          });
          ReactGA.pageview(window.location.pathname + window.location.search);
        //   document.title="Family Communication - DigiWill-Keep The Track Record of Your Assets and Liabilities"
      },[])

  return (
  <div>
          {/* <Popper></Popper> */}
          <MetaTags>
                <title>Investment Tracker | Real-Time Investment Tracking System - DigiWill</title>
                <meta name="description" content="DigiWill's Investment Tracking feature enables users to share & sync the recorded information in real-time via a Linked account with family members." />
            </MetaTags>
   
    <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />  
<main>
       
        <section id="cxn-banner">
            <div className="container" style={{height:"100%"}}>
                <div className="cxn-banner-outer d-flex align-items-center">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="cxn-banner-content">
                                <h2>Real-Time Asset Tracking System and Event based Communication System</h2>
                                <div className="cx-btn-grp">
                                    <a  href={PLAYSTORE_URL} className="cx-btn" type="button">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 



        <section id="cxn-exp">
            <div className="container">
                <div className="cxn-outer">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="cxn-content">
                                <p>The user can use the Family Communication feature to communicate with his or her family members/loved ones/nominees about DigiWill in real-time or choose custom event based communication.</p>
                                <ul className="cxn-list">
                                    <li><h4>Real-Time Asset Tracking System</h4>
                                        <p>This feature enables the users to share and sync their recorded information in real time via Linked account, in real-time with their family members.</p>
                                        <img src="./images/home/bullet_tick.svg" alt="bullet-tick" />
                                    </li>
                                    <li><h4>Event based Communication System</h4>
                                        <p>This feature will help you set custom event based communication of your financial and other information with anyone you want.</p>
                                        <img src="./images/home/bullet_tick.svg" alt="bullet-tick" />
                                    </li>
                                </ul>
                                <div className="cx-btn-grp">
                                <a  href={PLAYSTORE_URL} className="cx-btn" type="button">Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="cxn-content d-flex justify-content-center align-items-center" style={{height:"100 %"}}>
                                <img src="./images/explore/party.png" alt='optional'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section id="cxn-download">
            <div className="container">
                <div className="cxn-outer">
                    <div className="cxn-download-content">
                        <div className="cxn-download-btn">
                            {/* <a href={PLAYSTORE_URL} style={{marginRight:"8 rem"}}><img src="./images/home/google_play.png" alt='optional'/></a> */}
                      {/*       <a href="#"><img src="./images/home/google_play.png" /></a> */}
                        </div>
                        <h4>Download the most secure online document storage app for android and iOS devices now!</h4>
                    </div>
                </div>
            </div>
        </section>
<FooterEnsurance></FooterEnsurance>

    </main>


  </div>
  
  );
}

export default Tracker;
