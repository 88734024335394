import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL } from './urls';


function PriceVideoNew() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (


            <section id="cx-banner-price" className=' '>
                <div className="container">
                    <div className="cx-banner-outer">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-5 ">
                                <div className="cx-banner-content-price-new">
                                    <h2 >Our Pricing</h2>
                                    <ul>
                                        <li>Simple transparent plans.</li>
                                        <li>Forget the retainers.</li>
                                        <li>Everything is included in one single subscription.</li>
                                    </ul>                           
                                    <Link to="/pricing" > <a className="cx-btn-pricing-new mt-3" aria-current="page" href="#" >Know More...</a></Link>
                                    <div className="cx-banner-buttons d-flex">
                                        {/* <a href={PLAYSTORE_URL} className="cx-banner-btns"><img src="images/home/google_play.png" style={{height:"43px",width:"146px"}} alt="google_store" /></a> */}
                                        {/*         <button className="cx-banner-btns"><img src="images/home/app_store.png" alt="google_store" /></button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="cx-banner-content d-flex ">
                                    <video width='750'
                                        height='100%'
                                        src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/website+pricing+video+29th+nov.mp4"
                                        muted
                                        autoPlay={"autoplay"}
                                        preload="auto"
                                        loop
                                        playsInline
                                        disablePictureInPicture
                                        aspectRatio= {1}
                                        title='DigiWill Pricing'
                                    ></video>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




     
    )
}

export default PriceVideoNew;