import React from 'react'
import FooterApp from './FooterApp'
import NavbarApp from './NavbarApp'
import PageSectionOne from './PageSectionOne'

import MetaTags from 'react-meta-tags';



const AppLanding = () => {
  
  return (
    
    <div  style={{background:"#000000"}}>
       <MetaTags>
            <title>DigiWill - A One-Stop Solution for Building Your Financial Future!</title>
            <meta name="description" content="DigiWill is an easy way to secure your assets, It helps you with will creation, asset distribution, and whatnot – we cover it all under one app!" />
        </MetaTags>

        <NavbarApp/>
        <PageSectionOne/>
        <FooterApp/>


    </div>
  )
}

export default AppLanding
