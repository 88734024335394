import { border } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const B2bEmpAdd = () => {
  
    useEffect(() => {
        $(document).ready(function () {
            $('#displayempdata').DataTable();
        });
    });

    return (
        <>
            <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
                {/* <!-- Navbar Brand--> */}
                <a class="navbar-brand ps-3" href="index.html"><img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/whitee.png" alt="" style={{ width: "70px" }} /></a>
                {/* <!-- Sidebar Toggle--> */}
                <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i class="fas fa-bars"></i></button>
                {/* <!-- Navbar Search--> */}
                <div className='mt-2 mx-3'>
                    <h6 className='text-light'>TATA MOTORS | #11221</h6>
                </div>
               <div className='d-flex flex-row '>
               <div className='mt-2'>
                    <h6 className='text-light'>Dhawal Mehta</h6>
                </div>
                {/* <!-- Navbar--> */}
                <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#!">Settings</a></li>
                            <li><a class="dropdown-item" href="#!">Activity Log</a></li>
                            <li><hr class="dropdown-divider" /></li>
                            <li><a class="dropdown-item" href="#!">Logout</a></li>
                        </ul>
                    </li>
                </ul>
               </div>
               
            </nav>
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                        <div class="sb-sidenav-menu">
                            <div class="nav">
                                <div class="sb-sidenav-menu-heading">Core</div>
                                <a class="nav-link" href="index.html">
                                    <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                    Dashboard
                                </a>
                                <div class="sb-sidenav-menu-heading">Interface</div>
                                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                                    Layouts
                                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                </a>
                                <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav class="sb-sidenav-menu-nested nav">
                                        <a class="nav-link" href="layout-static.html">Static Navigation</a>
                                        <a class="nav-link" href="layout-sidenav-light.html">Light Sidenav</a>
                                    </nav>
                                </div>
                                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                                    <div class="sb-nav-link-icon"><i class="fas fa-book-open"></i></div>
                                    Pages
                                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                </a>
                                <div class="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                                    <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                                        <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">
                                            Authentication
                                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                        </a>
                                        <div class="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                            <nav class="sb-sidenav-menu-nested nav">
                                                <a class="nav-link" href="login.html">Login</a>
                                                <a class="nav-link" href="register.html">Register</a>
                                                <a class="nav-link" href="password.html">Forgot Password</a>
                                            </nav>
                                        </div>
                                        <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseError" aria-expanded="false" aria-controls="pagesCollapseError">
                                            Error
                                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                        </a>
                                        <div class="collapse" id="pagesCollapseError" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                            <nav class="sb-sidenav-menu-nested nav">
                                                <a class="nav-link" href="401.html">401 Page</a>
                                                <a class="nav-link" href="404.html">404 Page</a>
                                                <a class="nav-link" href="500.html">500 Page</a>
                                            </nav>
                                        </div>
                                    </nav>
                                </div>
                                <div class="sb-sidenav-menu-heading">Addons</div>
                                <a class="nav-link" href="charts.html">
                                    <div class="sb-nav-link-icon"><i class="fas fa-chart-area"></i></div>
                                    Charts
                                </a>
                                <a class="nav-link" href="tables.html">
                                    <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
                                    Tables
                                </a>
                            </div>
                        </div>
                        <div class="sb-sidenav-footer">
                            <div class="small">Logged in as:</div>
                            Start Bootstrap
                        </div>
                    </nav>
                </div>
                <div id="layoutSidenav_content">
                    <main>
                        <div class="container-fluid px-4">
                            <h1 class="mt-4">Enter Company details</h1>
                            <ol class="breadcrumb mb-4">
                                <li class="breadcrumb-item active">Dashboard</li>
                            </ol>
                            {/* add employee card wrapper */}
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Link to="/business-page-single-emp-add"><div >
                                        <div class="card text-center ">
                                            <div class="card-body">
                                                <i class="bi bi-plus-circle fa-2x text-success "></i>
                                                <h5 class="card-title text-success">Add Employee</h5>
                                                <p class="card-text">Add Individual Employee details</p>

                                            </div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className='col-md-6'>
                                    <Link to="/business-page-multiple-emp-add">
                                        <div >
                                            <div class="card text-center">
                                                <div class="card-body">
                                                    <i class="bi bi-plus-circle fa-2x text-success"></i>
                                                    <h5 class="card-title text-success">Bulk Upload</h5>
                                                    <p class="card-text">Add CSV file to add details in Bulk</p>

                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            {/* button click single and multiple employee adding wrapper  */}
                            {/* <div className='row'>
                                <div className='col-md-12 border mt-5 mb-4' >
                                    {isSingleEmp && (
                                        <>
                                            <div className="row mt-1 mb-4">
                                                <h4 className='mt-3'>Employees Information</h4 >
                                                <form class="row g-3 needs-validation" novalidate>
                                                    <div class="col-md-6 mb-2">
                                                        <label class="form-label">Name:</label>
                                                        <input type="text" class="form-control" required />

                                                    </div>
                                                    <div class="col-md-6  mb-2">
                                                        <label for="validationCustom02" class="form-label">Employee ID:</label>
                                                        <input type="text" class="form-control" value="Otto" required />

                                                    </div>
                                                    <div class="col-md-6  mb-2">
                                                        <label class="form-label">Email:</label>
                                                        <input type="text" class="form-control" required />

                                                    </div>
                                                    <div class="col-md-6  mb-2">
                                                        <label class="form-label">Mobile Number:</label>
                                                        <input type="text" class="form-control" required />

                                                    </div>
                                                    <div class="col-md-6  mb-2">
                                                        <label class="form-label">Aadhar Number:</label>
                                                        <input type="text" class="form-control" required />
                                                    </div>

                                                    <div class="col-12 ">
                                                        <button class="btn btn-success" type="submit">Submit form</button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className='row border mt-4'>
                                                <div className='col-lg-6'>
                                                    <h6 className='mt-4'>Employees Details, Proceed with Payments below Once done adding Employees</h6>
                                                </div>
                                                <div className='col-lg-6 d-flex flex-column align-items-end mb-4 px-3'>
                                                    <h4 className='mt-4 text-success'>$5,000</h4>
                                                    <p className='text-success'>For 3/ Employees</p>
                                                    <button className='btn btn-success'>Proceed to payments</button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className='col-md-12'>
                                    {
                                        isMultipleEmp && (
                                            <>
                                                <div className='row'>
                                                    <div className='col-lg-12 mb-3'>
                                                        <div class="card">

                                                            <div class="card-body">
                                                                <h5 class="">Bulk Uploads</h5>
                                                                <p class="card-text">In order to add bulk entries Download the Excel file attached, The data if not entered properly in ‘Sample Bulk Data file’
                                                                    wont show in the system. <span className='text-danger'> Don’t change the sample file pattern just enter data and Upload</span></p>
                                                                <button type="button" class="btn btn-outline-success d-flex flex-row align-items-center m-auto px-4 py-2 mt-3 mb-3">Download ‘Sample Bulk Data’ File</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div class="card">

                                                            <div class="card-body">
                                                                <h5 class="card-title">Upload File</h5>
                                                                <p class="card-text">After you have entered the data in the ‘Sample Bulk Data file’ downloaded<br /> from above - Upload the file Below.</p>
                                                                <div className='d-flex flex-row justify-content-between '>
                                                                    <div>
                                                                        <button class="btn btn-secondary px-3 py-2" onClick={toggleUpload} id="upload">Upload CSV file </button>
                                                                    </div>
                                                                    <div>
                                                                        <button className='btn btn-outline-success py-2 mx-1'><i class="bi bi-trash"></i></button>
                                                                        <button class="btn btn-success px-3 py-2">Upload Now </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div className='col-md-12'>
                                    {
                                        isUpload && (
                                            <>
                                                <div class="container-fluid p-0 ">
                                                    <h4 class="mt-4">Employee Data</h4>


                                                    <div class="card mb-4">
                                                        <div class="card-header">
                                                            <i class="fas fa-table me-1"></i>
                                                            DataTable Example
                                                        </div>
                                                        <div class="card-body">
                                                            <table id="datatablesSimple" >
                                                                <thead>
                                                                    <tr>
                                                                        <th> <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                                                                        <th>Sr No</th>
                                                                        <th>Employee Name</th>
                                                                        <th>Employee ID</th>
                                                                        <th>Email</th>
                                                                        <th>Mobile Number</th>
                                                                        <th>Aadhar Number</th>
                                                                        <th>Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tfoot>

                                                                </tfoot>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                                                        <td>1</td>
                                                                        <td>Tiger pandit</td>
                                                                        <td>023456</td>
                                                                        <td>tigerpan@gmail.com</td>
                                                                        <td>9123456789</td>
                                                                        <td>123412233410</td>
                                                                        <td className='text-success'>Eligible</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                                                        <td>2</td>
                                                                        <td>Suyog Suryawanshi</td>
                                                                        <td>023456</td>
                                                                        <td>danish@gmail.com</td>
                                                                        <td>9123456789</td>
                                                                        <td>12345678900</td>
                                                                        <td className='text-success'>Eligible</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                                                        <td>3</td>
                                                                        <td>Tiger Nixon</td>
                                                                        <td>023456</td>
                                                                        <td>danish@gmail.com</td>
                                                                        <td>9123456789</td>
                                                                        <td>12345678900</td>
                                                                        <td className='text-success'>Eligible</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                                                        <td>4</td>
                                                                        <td>Suyog Suryawanshi</td>
                                                                        <td>023456</td>
                                                                        <td>danish@gmail.com</td>
                                                                        <td>9123456789</td>
                                                                        <td>12345678900</td>
                                                                        <td className='text-success'>Eligible</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                                                        <td>5</td>
                                                                        <td>Tiger Nixon</td>
                                                                        <td>023456</td>
                                                                        <td>danish@gmail.com</td>
                                                                        <td>9123456789</td>
                                                                        <td>12345678900</td>
                                                                        <td className='text-success'>Eligible</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                                                        <td>6</td>
                                                                        <td>Suyog ss</td>
                                                                        <td>023456</td>
                                                                        <td>danish@gmail.com</td>
                                                                        <td>9123456789</td>
                                                                        <td>12345678900</td>
                                                                        <td className='text-success'>Eligible</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                                                        <td>7</td>
                                                                        <td>Suyog Suryawanshi</td>
                                                                        <td>023456</td>
                                                                        <td>suyog@gmail.com</td>
                                                                        <td>9123456789</td>
                                                                        <td>12345678900</td>
                                                                        <td className='text-success'>Eligible</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                                                        <td>8</td>
                                                                        <td>danny Nixon</td>
                                                                        <td>023456</td>
                                                                        <td>danny@gmail.com</td>
                                                                        <td>9123456789</td>
                                                                        <td>12345678900</td>
                                                                        <td className='text-success'>Eligible</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                                                        <td>9</td>
                                                                        <td>Tiger Nixon</td>
                                                                        <td>023456</td>
                                                                        <td>tiger@gmail.com</td>
                                                                        <td>9123456789</td>
                                                                        <td>12345678900</td>
                                                                        <td className='text-success'>Eligible</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                                                        <td>10</td>
                                                                        <td>Shail G</td>
                                                                        <td>023456</td>
                                                                        <td>sahil@gmail.com</td>
                                                                        <td>9123456789</td>
                                                                        <td>12345678900</td>
                                                                        <td className='text-success'>Eligible</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                        )
                                    }




                                </div>
                            </div> */}
                            {/* employee data table wrapper */}
                            <div className='row'>
                                <div className='col-lg-12 col-12'>
                                    <div class="container-fluid p-0 ">
                                        <h4 class="mt-4">Employee Data</h4>


                                        <div class="card mb-4">
                                            <div class="card-header">
                                                <i class="fas fa-table me-1"></i>
                                                DataTable Example
                                            </div>
                                            <div class="card-body">
                                                <table id="displayempdata">
                                                    <thead>
                                                        <tr>

                                                            <th>Employee Name</th>
                                                            <th>Employee ID</th>
                                                            <th>Mobile Number</th>
                                                            <th>Status</th>
                                                            <th>Actions</th>

                                                        </tr>
                                                    </thead>
                                                    <tfoot>

                                                    </tfoot>

                                                    <tbody>
                                                        <tr>

                                                            <td>Herrod Chandler</td>
                                                            <td>023456</td>
                                                            <td>91234567890</td>
                                                            <td className='text-danger'>N/A</td>
                                                            <td>
                                                                <button className='btn btn-success'>Action</button>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td>Tiger Nixon</td>
                                                            <td>023456</td>
                                                            <td>91234567890</td>
                                                            <td>Eligible</td>
                                                            <td>
                                                                <button className='btn btn-success'>Action</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Airi Satou</td>
                                                            <td>023456</td>
                                                            <td>91234567890</td>
                                                            <td className='text-danger'>N/A</td>
                                                            <td>
                                                                <button className='btn btn-success'>Action</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>TCedric Kelly</td>
                                                            <td>023456</td>
                                                            <td>91234567890</td>
                                                            <td>Eligible</td>
                                                            <td>
                                                                <button className='btn btn-success'>Action</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ashton Cox</td>
                                                            <td>023456</td>
                                                            <td>91234567890</td>
                                                            <td className='text-danger'>N/A</td>
                                                            <td>
                                                                <button className='btn btn-success'>Action</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Garrett Winters</td>
                                                            <td>023456</td>
                                                            <td>91234567890</td>
                                                            <td className='text-danger'>N/A</td>
                                                            <td>
                                                                <button className='btn btn-success'>Action</button>
                                                            </td>
                                                        </tr>


                                                    </tbody>

                                                </table>
                                                <div className='col-lg-12 col-12 d-flex justify-content-center'>
                                                    <Link to="/business-page-employee-status"> <a href="">See More</a> </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                </div>
            </div>
        </>
    )
}

export default B2bEmpAdd;
