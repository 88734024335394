import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BASE_URL } from './urls';


function YoutubeVideos() {
    const [videos, setVideos] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        const LoadYoutube = async () => {
            const response = await fetch(`${BASE_URL}videos`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()
            console.log(data);
            setVideos(data)


        }
        LoadYoutube()
    }, [])
    return (
        <div>
           
          

            <section id="cx-video" className=''>
                <div className="container">
                    <div className="cx-outer">
                        <div className="cx-content-header cx-video-header mb-5">
                            <h2>Not your typical investment agency!</h2>
                        </div>
                        <br></br>
                        <div>


                            {/*          <div className="owl-carousel owl-theme"  id="cx-owl"> */}
                        <OwlCarousel className='owl-theme owl-carousel'  loop={true} video={true}  lazyLoad={true} margin={30} autoplay={true}  autoplayHoverPause={true} responsive={{
                                0: {
                                    items: 1,

                                },
                                600: {
                                    items: 3,

                                },
                                1000: {
                                    items: 3,
                                    loop: true,
                                   
                                }
                            }} autoPlay={true} dots={false} autoplayTimeout={3000}    >

                                {
                                    videos.map((single) => {
                                        return (

                                            <div className="item">

                                                <iframe style={{ width: "100%" }} src={single.url} title="YouTube video player" frameBorder="0" allow="accelerometer; video;autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture "  allowFullScreen></iframe>
                                                <p>{single.desc}</p>
                                            </div>

                                        )
                                    })
                                }

                                {/* 
                    <div className="item">
                        <iframe style={{width:"100%"}} src="https://www.youtube.com/embed/G9kaso_ijmI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <p>Solution to keeps your information beyond safe - DigiWill App</p>
                    </div>
                    <div className="item">
                        <iframe style={{width:"100%"}} src="https://www.youtube.com/embed/XYtbPffrFUs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <p>DigiWill Allows You To Easily Keep A Record Of Your Assets And Liabilities.</p>
                    </div>
                    <div className="item">
                        <iframe  style={{width:"100%"}} src="https://www.youtube.com/embed/G9kaso_ijmI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <p>I have used similar applications in the past, and the major problem was ads.</p>
                    </div>
                    <div className="item">
                        <iframe style={{width:"100%"}} src="https://www.youtube.com/embed/G9kaso_ijmI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <p>I have used similar applications in the past, and the major problem was ads.</p>
                    </div> */}
                                {/*   </div> */}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default YoutubeVideos