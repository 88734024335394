import React,{useState,useEffect} from 'react'

import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar'
import '../pages/Security.css'
import MetaTags from 'react-meta-tags';
import { simpleGetCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';


function Security() {

    const [progress, setProgress] = useState(0)
    useEffect(() => {
      window.scrollTo(0, 0)
  }, [])
  const [content,setcontent]=useState("")

  function createMarkup(data) {
      return { __html: data };
  }
  useEffect(()=>{


      simpleGetCallWithErrorResponse(ApiConfig.DESCLAIMER).then((data) => {
          setcontent(data.json)

      })
          .catch((error) => {
              console.log("api response", error);
          });
  },[])

    useEffect(

        ()=>{
            setProgress(100)
            document.title="Security Policy - DigiWill-Keep The Track Record of Your Assets and Liabilities"
        },[]
    )

  return (
    <div>
      <MetaTags>
                	<title>Security Policy | Information about Transfer of Data</title>
               	 <meta name="description" content="We will never rent or sell your information or data to anyone. We never use or transfer your data for serving ads, including retargeting, personalised, or interest based advertising" />
            	</MetaTags>


    {/* <Popper></Popper> */}
   

    <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />  
  <section className="cxle-banner">
            <div className="container">
                <div clas="row " style={{height:"100%"}}>
                    <div className="col-12 d-flex align-items-center justify-content-center" style={{height:"100%"}}>
                        <div className="cxle-banner-content">
                            <span>Security Policy</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<section id="policy">
    <div className="policy_block">
        <div className="container">
           
        {
    content ? 
    <div>
   <p dangerouslySetInnerHTML={createMarkup(content)} /> 
    </div>

    :""
}
       
        </div>
    </div>
</section>
        
    </div>
  )
}

export default Security