
import React, { useEffect, useRef, useState, useContext } from 'react';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import ApiConfig from '../../api/ApiConfig';
import { PostCallWithErrorResponse } from '../../api/ApiServices';

const B2bLogin = () => {
    const [hidden, setHidden] = useState(true);
    const [mobile_no, setMobileNo] = useState();
    const [otp, setOtp] = useState();
    const navigate = useNavigate();

    const getOtp = (e) => {
        e.preventDefault()
        PostCallWithErrorResponse(ApiConfig.LOGIN_PHONE_NO, { mobile_no: mobile_no }

        ).then((data) => {
            console.log(data.json)

            if (data.json) {
                setHidden();
                otpScreen();
            }
            else {
                swal({
                    title: "Alert",
                    text: data.json.message,
                    icon: "warning",
                });
            }
        })
            .catch((error) => {
                // setHidden(false)
                console.log("api response", error);
            });
    }


    const handleotpSubmit = (e) => {
        e.preventDefault()

        PostCallWithErrorResponse(ApiConfig.OTP_VERIFY_SCREEN, { mobile_no: "+91" + mobile_no, mobile_no_otp: otp }

        ).then((data) => {
            if (data.json) {
                swal({
                    title: "Alert",
                    text: data.json.message,
                    icon: "warning",
                });
                localStorage.setItem('token', data.json.token)
                navigate('/business-page-company-deatils')
            }
            else {
                swal({
                    title: "Alert",
                    text: data.json.message,
                    icon: "warning",
                });
                navigate('/')
            }
        })
            .catch((error) => {
                console.log("api response", error);
            });

    }

    function otpScreen() {

        if (document.getElementById('Div1')) {

            if (document.getElementById('Div1').style.display == 'none') {
                document.getElementById('Div1').style.display = 'block';
                document.getElementById('Div2').style.display = 'none';
            }
            else {
                document.getElementById('Div1').style.display = 'none';
                document.getElementById('Div2').style.display = 'block';
            }
        }

    }

    return (
        <>
            <div id="layoutAuthentication" style={{ background: "#363740" }}>
                <div id="layoutAuthentication_content">
                    <main>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-12">
                                    <div class="card shadow-lg border-0 rounded-lg mt-5">

                                        <div class="card-header" style={{ backgroundColor: "white" }}>
                                            <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/whitee.png" alt="" className='mt-4' style={{ width: "100px", height: "100%", display: "flex", margin: "auto" }} />
                                            <h3 class="text-center font-weight-light my-4">Login</h3>
                                            <p class="text-center font-weight-light my-4" style={{ color: "#9FA2B4" }}>Enter your email and password below sent on your email.</p>
                                        </div>
                                        <div class="card-body login-card-wrapper">
                                            <form>
                                                <div id='Div1'>
                                                    <div class="form-group mb-3" >
                                                        <label for="inputPassword" className='mb-1'>Phone No.</label>
                                                        <div class="input-group" id="">
                                                            <input id="phone" class="form-control" value={mobile_no} onChange={(e) => setMobileNo(e.target.value)} pattern="[0-9]{10}" type="tel" placeholder='Enter Mobile No.' required />
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-between mt-4 mb-5" type="submit" onClick={getOtp} >
                                                        <a class="btn btn-primary cx-btn-login" href="#" >Submit</a>
                                                    </div>
                                                </div>

                                                <div id="Div2" style={{ display: hidden ? 'none' : 'block' }}>
                                                    <div class="form-group mb-3" >
                                                        <label for="inputPassword" className='mb-1'>Please Enter Otp</label>
                                                        <div class="input-group" id="show_hide_password">
                                                            <input class="form-control" maxlength="4" value={otp} onChange={(e) => setOtp(e.target.value)} type="tel" placeholder='OTP' />
                                                            <div class="input-group-addon-wp">
                                                                <a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-between mt-4 mb-5" type="submit" onClick={handleotpSubmit} >
                                                        <a class="btn btn-primary cx-btn-login" href="#" >Submit</a>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </div>

        </>

    )
}

export default B2bLogin
