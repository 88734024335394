import React from 'react'

const NavbarApp = () => {
    return (
        <div>

            <nav class="navbar navbar-light ">
                <div class="container nav-set">
                    <a class="navbar-brand app-logo" href="#"><img src='https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/whitee.png'/></a>
                </div>
            </nav>



        </div>
    )
}

export default NavbarApp
