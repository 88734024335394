import React, { useState, useEffect,useContext } from 'react';
import Typewriter from 'typewriter-effect';
import { BASE_URLS } from './urls';
 
import BeSequred from './BeSequred'; 
import { AppContext } from '../../user/AppContext';
function FeaturesVideo() {
    const width = window.innerWidth
    const {show,setShow}=useContext(AppContext)
    useEffect(() => {

        if (width <= 972) {
          setShow('none')
        }
        else{
          setShow('flex')
        }
    
      }, [])
    return (
        // this is features video for home screen section start 
        <section id="cx-banner-feature" className=' '>
            <div className="container-fluid">
            <BeSequred /> 
                <div className="cx-banner-outer-feature">
                    {/* <div className="cx-content-header mb-4">
                        <h2 className='mb-2 align-center cx-features-heading'>Designed with your security in mind, <span className='text-dark blink_me'>Tokenization + Encryption</span> for the first time.</h2>
                        <p className='text-center'> <Typewriter
                            options={{
                                strings: ['We are proud to say that we don’t need to harvest your Data to make money.'],
                                autoStart: true,
                                loop: true,
                                pauseFor: 600000,
                            }}
                        />
                        </p>
                    </div> */}
                    <div className='features-wrapper'>
                        <div className="col-lg-10 col-md-10 col-12">
                            <div className="cx-features-video ">
                                <video width='100%'
                                    height='100%'
                                    src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/security+services+version+2updated+22+dec+version+black.mp4"
                                    muted 
                                    autoPlay={"autoplay"}
                                    preload="auto"
                                    loop
                                   
                                    webkit-playsInline="true" 
                                    playsInline="true"
                                    disablePictureInPicture
                                    aspectRatio={1}
                                    title='Data secured by AWS'
                                ></video>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>





    )
}

export default FeaturesVideo;