import React, { useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { BASE_URL, PLAYSTORE_URL } from './pages/urls';
import { useAlert } from 'react-alert'

function Footer() {

    const [success, setsuccess] = useState(false)
    const [progress, setProgress] = useState(0)
    const alert = useAlert()
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [contactData, setContactData] = useState({ name: '', email: "", message: "" })
    const closemodal = useRef()
    const handletoggle = (e) => {
        setShow(!show)

    }
    const handletoggle2 = (e) => {

        setShow2(!show2)

    }
    const handleContactRegister = async (e) => {
        e.preventDefault()

        const { name, email, message } = e.target
        const data = new FormData();

        data.append('name', name.value)
        data.append('email', email.value)
        data.append("message", message.value)

        console.log(data);
        const response = await fetch(`${BASE_URL}contactus`, {
            method: "POST",
            body: data,
        })
        const newdata = await response.json()
        console.log(newdata);
        setsuccess(true)
        setContactData({ name: '', email: "", message: "" })
        setTimeout(() => {
            setProgress(25)
        }, 100);
        setTimeout(() => {
            setProgress(70)
        }, 100);
        setTimeout(() => {
            setProgress(100)
        }, 100);
        closemodal.current.click()

        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })




    }

    const handleChange = (e) => {

        if (e.target.name === 'name') {

            setContactData({ ...contactData, name: e.target.value })
        }


        if (e.target.name === 'email') {

            setContactData({ ...contactData, email: e.target.value })
        }


        if (e.target.name === 'message') {

            setContactData({ ...contactData, message: e.target.value })
        }

    }

    return (
        <>

            <footer id="cx-footer">
                <div className="container">
                    <div className="cx-outer">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="cx-footer-content">
                                    <img className="cx-logo" src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/dw3.png" alt='DigiWill - Aapki Virasat'/>
                                    <p className='text-light'>Our personality can be defined by the Acronym C.R.E.D
                                        where C stands for Confidential, R stands for Real,
                                        E is for Empathetic,and D is for Dynamic.
                                        These four words are the cornerstone of our product,
                                        we value the secrecy of our users,
                                        and we have made a product that is in sync with the
                                        reality around it apart from that the idea behind the
                                        product comes from a deep emotional muse
                                        reflecting empathy and finally our product
                                        is intended to be ever-changing and ever-growing.</p>
                                    <div className="cx-download">
                                        <h4 >Download Our App</h4>
                                        <div className="cx-download-btn">
                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png"  style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                            {/*    <a href="#"><img src="images/home/app_store.png" /></a> */}
                                        </div>
                                    </div>
                                    {/* <ul className="cx-social">
                                        <li><a href="https://www.facebook.com/DigiWillApp/"><i className='bx bxl-facebook-circle'></i></a></li>
                                        <li><a href="https://www.instagram.com/DigiWill_/"><i className='bx bxl-instagram-alt' ></i></a></li>
                                        <li><a href="https://twitter.com/DigiWillsocial"><i className='bx bxl-twitter' ></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/DigiWill/"><i className='bx bxl-linkedin-square' ></i></a></li>
                                        <li><a href="https://www.youtube.com/channel/UCPxiy6bM05RU41iVS3sdjDg/"><i class='bx bxl-youtube'></i></a></li>
                                    </ul> */}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="cx-footer-content">
                                    <h4>Links</h4>
                                    <ul className="cx-links">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/blog">Blog</Link></li>

                                        {/* <li className="cx-exp-toggler" onClick={(e) => handletoggle2(e)}>
                                            <a>Explore</a>
                                            <div className={show2 ? "cx-exp cx-active" : "cx-exp"}>
                                                <div className="cx-exp-title">
                                                    <h4>Explore</h4>
                                                    <button type="button" className="cx-nav-close"><i className="bx bx-x"></i></button>
                                                </div>
                                                <ul>
                                                    <li><Link to="/">Home</Link></li>
                                                    <li><Link to="/tracker">Family Communication</Link></li>
                                                    <li><Link to="/ensurance">Ensurance Plan</Link></li>
                                                    <li><Link to="/service_partner">Service Partners</Link></li>
                                                    <li><Link to="/channel_partner">Channel Partners</Link></li>
                                                    <li><Link to="/invest">Invest In</Link></li>



                                                    <li><a href="#">Self Interest Will Draft</a></li>
                                                    <li><a className="dropdown-item" href="#">Digicoin</a></li>
                                                    <li><Link to="/hot_deals">Hotdeals</Link></li>
                                                </ul>
                                            </div>
                                        </li> */}
                                        {/* <li className="cx-res-toggle " onClick={(e) => handletoggle(e)} >
                                            <a>Resources</a>
                                            <div className={show ? "cx-res cx-active" : "cx-res"} >
                                                <div className="cx-res-title">
                                                    <h4>Resources</h4>
                                                    <button type="button" className="cx-rnav-close"><i className="bx bx-x"></i></button>
                                                </div>
                                                <ul>
                                                    <li><Link to="/blog">Blogs</Link></li>
                                                    <li><Link to="/videos">Videos</Link></li>
                                                    <li><Link to="/curosity_box">Curiosity Box</Link></li>
                                                            <li><a className="dropdown-item" href="#">Media</a></li> 
                                                    <li><Link to="/about">About Us</Link></li>
                                                    <li><a className="dropdown-item" href="#">Contact Us</a></li> 
                                                </ul>
                                            </div>
                                        </li> */}
                                        <li><Link to="/pricing">Pricing</Link></li>
                                        {/* <li data-bs-toggle="modal" data-bs-target="#cx-contact">
                                            <a aria-current="page" href="#">Contact</a>
                                        </li> */}
                                        <li><Link to="/contact-get">Contact</Link></li>
                                        <li><Link to="/claim-assistance">Claim-Assistance</Link></li>
                                        <li><Link to="/videos">Podcast</Link></li>
                                        {/* <li><Link to="/app-launching-soon">App Launch</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3">

                                <div className="cx-footer-content" style={{ marginBottom: 0, border: 0, paddingBottom: 0 }}>
                                    <h4>Contact</h4>
                                    <ul className="cx-contact">
                                        {/* <li>+917559478151<i className='bx bx-phone-call' ></i></li> */}
                                        <li> <a href="mailto:info@yourdigiwill.com" >info@yourdigiwill.com</a>
                                            <i className='bx bx-mail-send' ></i></li>
                                    </ul>
                                    
                                </div>
                                <div className='mt-3 cx-footer-content'>
                                    <h4 className='mb-3'>Our Social Icon</h4>
                                    <ul className="cx-social">
                                        <li><a href="https://www.facebook.com/DigiWillApp/"><i className='bx bxl-facebook-circle'></i></a></li>
                                        <li><a href="https://www.instagram.com/DigiWill_/"><i className='bx bxl-instagram-alt' ></i></a></li>
                                        <li><a href="https://twitter.com/DigiWillsocial"><i className='bx bxl-twitter' ></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/DigiWill/"><i className='bx bxl-linkedin-square' ></i></a></li>
                                        <li><a href="https://www.youtube.com/channel/UCPxiy6bM05RU41iVS3sdjDg/"><i class='bx bxl-youtube'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cx-footer-legal">
                    <p className='cx-footer-seclast'><Link to="/security-policy">Security Policy</Link> | <Link to="privacy-policy">Privacy Policy</Link> | <Link to="/terms-conditions">Terms & Conditions</Link> | <a href="/cancle-and-refund-policy">Cancellation and Refund Policy</a> | <Link to='/faq'>Help Center</Link></p>
                </div>
                <div className="cx-footer-copyright">
                    <p>© 2024 Servfar Services Pvt Ltd  All Rights Reserved. Made In Pune With <i className='bx bxs-heart'></i></p>
                </div>
            </footer>
            <div className="modal fade " id="cx-contact" tabIndex="-1" role="dialog" aria-labelledby="cx-contactTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" >
                        <button type="button" className="close-btn" ref={closemodal} data-bs-dismiss="modal" aria-label="Close"><i className='bx bxs-x-circle' ></i></button>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="cx-contact-content">
                                    <img src="./images/contact/contact_image.png" />
                                    <h2 style={{ marginTop: "30px", textAlign: "left" }}>Address</h2>

                                    <ul>
                                        <li><i className='bx bx-map' ></i>2, Gagangiri Complex, Pune, 411015<br />Maharashtra </li>
                                        <li style={{ cursor: 'pointer' }}><i className='bx bx-mail-send' ></i>

                                            <a href="mailto:info@yourdigiwill.com">info@yourdigiwill.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="cx-contact-content">
                                    <h2 className="cx-cont">Contact Us</h2>
                                    <p>Fill in the below form and we’ll reach out to you</p>
                                    <form onSubmit={(e) => handleContactRegister(e)}   >

                                        <div className="mb-3">
                                            <input type="text" name="name" value={contactData.name} onChange={(e) => handleChange(e)} className="form-control" id="exampleInputEmail1" placeholder="Name" />
                                        </div>
                                        <div className="mb-3">
                                            <input type="email" name="email" value={contactData.email} onChange={(e) => handleChange(e)} className="form-control" id="exampleInputEmail1" placeholder="Email" />
                                        </div>
                                        <div className="mb-3">
                                            <textarea className="form-control" value={contactData.message} onChange={(e) => handleChange(e)} name="message" placeholder="Message" style={{ height: "180px" }}></textarea>
                                        </div>
                                        <div className="cx-contact-grp">
                                            <button type="submit" className="cx-contact-btn">Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )

}

export default Footer;
