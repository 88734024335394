import React, { useState, useEffect, useContext,useRef  } from 'react';
import { useAlert } from 'react-alert'
import { BASE_URLS, PLAYSTORE_URL } from './urls';

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import LoadingBar from 'react-top-loading-bar'
import { AppContext } from '../../user/AppContext';
import ReCAPTCHA from "react-google-recaptcha";
function FooterEnsurance() {

  const [registerData, setRegisterData] = useState({ name: '', email: "", phone_number: "" })
  const [progress, setProgress] = useState(0)

  const alert = useAlert()
  const width = window.innerWidth
  const { show, setShow } = useContext(AppContext)
  const [verifed, setVerifed] = useState(false);
  const captchaRef = useRef(null)
  function onChange(value) {
    console.log("Captcha value:", value);
    setVerifed(true);
  }
  useEffect(() => {

    if (width <= 972) {
      setShow('none')
    }
    else {
      setShow('flex')
    }

  }, [])
  useEffect(

    () => {
      setProgress(100)

    }, []
  )

  /* handle register start */
  const handleRegister = async (e) => {
    e.preventDefault()
    alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })

    const { name, email, phone_number, city, profession } = e.target
    const data = new FormData();

    data.append('name', name.value)
    data.append('email', email.value)
    data.append("phone_number", phone_number.value)

    console.log(data);
    const response = await fetch(`${BASE_URLS}tempensurdata`, {
      method: "POST",
      body: data,
    })
    
    const newdata = await response.json()
    console.log(newdata);

    setRegisterData({ name: '', email: "", phone_number: "" })
    setTimeout(() => {
      setProgress(25)
    }, 100);
    setTimeout(() => {
      setProgress(70)
    }, 100);
    setTimeout(() => {
      setProgress(100)
    }, 100);



  }

  /* handle register end  */

  /* form onchange event start */

  const handleChange = (e) => {

    if (e.target.name === 'name') {

      setRegisterData({ ...registerData, name: e.target.value })
    }


    if (e.target.name === 'email') {

      setRegisterData({ ...registerData, email: e.target.value })
    }


    if (e.target.name === 'phone_number') {

      setRegisterData({ ...registerData, phone_number: e.target.value })
    }

  }

  /* form onchange event end  */

  return (<div>



    <section id="cx-secure" style={{ marginRight: "1 rem" }}>
      <div className="container">
        <div className="cx-outer">
          <div className="cx-content-header cx-secure-header">
            <h2>Secure yourself and your loved ones now</h2>
          </div>
          <div className="cx-secure-content" >
            <form className="d-flex flex-wrap flex-row justify-content-evenly align-items-center" onSubmit={(e) => handleRegister(e)}>
              <input type="text" name='name' value={registerData.name} onChange={(e) => handleChange(e)} className="form-control" placeholder="Your Name" />
              <input type="text" name='email' value={registerData.email} onChange={(e) => handleChange(e)} className="form-control" placeholder="Email" />
              <input type="text" name='phone_number' value={registerData.phone_number} onChange={(e) => handleChange(e)} className="form-control" placeholder="Phone Number" />
              <div className=' d-flex flex-row justify-content-center'>
                                            <div className="captcha" style={{ transform: "scale(0.85)" }}>
                                                <ReCAPTCHA
                                                    sitekey="6LfPk6MjAAAAAILoHwcOKf-55xEIYFxc9tyWQzsw"
                                                    onChange={onChange}
                                                    ref={captchaRef}
                                                   
                                                />
                                            </div>
                                        </div>
              <button type="submit" className="cx-btn mt-3" disabled={!verifed}>Ensure Now</button>
            </form>
          </div>
        </div>
      </div>
    </section>


  </div>);
}

export default FooterEnsurance;
