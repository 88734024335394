import React, { useState, useEffect, useContext } from 'react';
import Typewriter from 'typewriter-effect';
import { BASE_URLS } from './urls';
import { AppContext } from '../../user/AppContext';
import Beprepared from './Beprepared';
import { BASE_URL, PLAYSTORE_URL } from './urls';
function HowWorkStep() {
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext)

    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section id="cx-how" className='' style={{ background: '#E0FFD6'}}>
            <div className="container-fluid p-0">
                <div className="cx-outer-how">
                    {/* <div className="cx-content-header">
                        <h2>Why Choose Us ?</h2>
                        {/* <p class="line-1 anim-typewriter">We provide best-in-class investment nominee services so you can build the plane as you fly it.</p> 

                    </div> */}
                    <Beprepared />

                    <div className="cx-content cx-content-how-new">
                        <div id="carouselExampleCaptions1" className="carousel  carousel-fade" data-bs-ride="carousel" data-pause="hover" >
                            <div className="row cx-cara-content">
                                <div className="col-lg-3 col-3 mt-3">
                                    <div className="carousel-indicators cx-indicators-new">
                                        <button type="button" data-bs-target=" #carouselExampleCaptions1" data-bs-slide-to="0" className="cx-in-btn active" aria-current="true" aria-label="Slide 1"><span>100% Legal</span></button>
                                        <button type="button" data-bs-target=" #carouselExampleCaptions1" data-bs-slide-to="1" className="cx-in-btn " aria-label="Slide 2" ><span>Retainer Free</span></button>
                                        <button type="button" data-bs-target=" #carouselExampleCaptions1" data-bs-slide-to="2" className="cx-in-btn" aria-label="Slide 3"><span>Ai Powered</span></button>
                                        <button type="button" data-bs-target=" #carouselExampleCaptions1" data-bs-slide-to="3" className="cx-in-btn" aria-label="Slide 4"><span>Tech Based Communication</span></button>
                                        <button type="button" data-bs-target=" #carouselExampleCaptions1" data-bs-slide-to="4" className="cx-in-btn" aria-label="Slide 5"><span>Hassle-Free Claims</span></button>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-9" >
                                    <div className="carousel-inner" >
                                        <div className="carousel-item active" data-bs-interval="3000" >
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 ">
                                                    <div className="cx-carousel-slide d-flex justify-content-center">
                                                        <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/peace+of.png" className="" alt="100% legal" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-12">
                                                    <div className="cx-carousel-caption-new" >
                                                        <h2 className='mb-3'>Peace Of Mind.</h2>
                                                        <p className='mb-3'>Crafted by legal and industry experts for <br></br>families.</p>
                                                        {/* <button className='btn bt-how mb-3'>Get More..</button> */}
                                                        <div className="cx-banner-buttons d-flex flex-row justify-content-start mb-3">
                                                              <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                                            {/* <button className="cx-banner-btns"><img src="images/home/app_store.png" alt="google_store" /></button>  */}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="3000" >
                                            <div className="row align-items-center">
                                                <div className="col-lg-5">
                                                    <div className="cx-carousel-slide d-flex justify-content-center">
                                                        <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/retainer.png" className="" alt="No retainer" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-7">
                                                    <div className="cx-carousel-caption-new ">

                                                        <h2 className='mb-3'>Forget The Retainers.</h2>
                                                        <p className='mb-3'>Everything at a one lifetime charge.</p>
                                                        {/* <button className='btn bt-how mb-3'>Get More..</button> */}
                                                        <div className="cx-banner-buttons d-flex flex-row justify-content-start">
                                                              <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                                            {/* <button className="cx-banner-btns"><img src="images/home/app_store.png" alt="google_store" /></button>  */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="3000" >
                                            <div className="row align-items-center">
                                                <div className="col-lg-5">
                                                    <div className="cx-carousel-slide d-flex justify-content-center">
                                                        <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/welath.png" className="d-block w-100" alt="AI-Powered assured wealth transfer" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 ">
                                                    <div className="cx-carousel-caption-new ">

                                                        <h2 className='mb-3'>Assured Wealth<br /> Transfer.</h2>
                                                        <p className='mb-3'>The only way to be there for your wife and kids.</p>
                                                        {/* <button className='btn bt-how mb-3'>Get More..</button> */}
                                                        <div className="cx-banner-buttons d-flex flex-row justify-content-start">
                                                              <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                                            {/* <button className="cx-banner-btns"><img src="images/home/app_store.png" alt="google_store" /></button>  */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="3000" >
                                            <div className="row align-items-center">
                                                <div className="col-lg-5">
                                                    <div className="cx-carousel-slide d-flex justify-content-center">
                                                        <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/automated.png" className="d-block w-100" alt="Human interference-free communication" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-7">
                                                    <div className="cx-carousel-caption-new  ">
                                                        <h6></h6>
                                                        <h2>Eliminate Human Dependancy.</h2>
                                                        <p>Prevent tampering, biased presentation, unwelcomed <br />claims and exploitation of loved ones. </p>
                                                        {/* <button className='btn bt-how mb-3'>Get More..</button> */}
                                                        <div className="cx-banner-buttons d-flex flex-row justify-content-start">
                                                            <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                                            {/* <button className="cx-banner-btns"><img src="images/home/app_store.png" alt="google_store" /></button>  */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="3000">
                                            <div className="row align-items-center">
                                                <div className="col-lg-5">
                                                    <div className="cx-carousel-slide d-flex justify-content-center ">
                                                        <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/hand+holding.png" className="d-block w-100" alt="Hassle free claims" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-7">
                                                    <div className="cx-carousel-caption-new">

                                                        <h2 className='mb-3'>Hand-Holded <br /> Services.</h2>
                                                        <p className='mb-3'>End your loved ones struggle to get what is rightfully theirs.<br /></p>
                                                        {/* <button className='btn bt-how mb-3'>Get More..</button> */}
                                                        <div className="cx-banner-buttons d-flex flex-row justify-content-start">
                                                              <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                                            {/* <button className="cx-banner-btns"><img src="images/home/app_store.png" alt="google_store" /></button>  */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </section >



    )
}

export default HowWorkStep;