import React, { useEffect, useState, useContext } from 'react';
import { TRACKING_CODE, BASE_URLS } from "./urls"
import { Routes, Route, useParams } from 'react-router-dom';
import { AppContext } from '../../user/AppContext';

import { Link } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar'
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags';

ReactGA.initialize(TRACKING_CODE);
function BlogHome() {

    const [blogs, setBlogs] = useState([])
    const [cats, setcats] = useState([])
    const [catsearch, setcatsearch] = useState([])
    const [noofElement, setnoofElement] = useState(3)
    // const [cat,setCat]=useState([])
    const [data, setData] = useState({ data: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');
    // const {slug}=params
    // console.log("hie params",params)
    const [progress, setProgress] = useState(0)
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext)
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited blog page'
        });
        //   document.title="Blog - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])


    useEffect(() => {
        const LoadBlogs = async () => {

            const response = await fetch(`${BASE_URLS}blog`,
                {
                    method: 'GET',

                }
            )
            const data = await response.json()
            console.log(data);

            setBlogs(data.Blog_Data)
            setProgress(100)

        }

        LoadBlogs()

    }, [])

    //cataory blog data show in another no 3 api
    const handleClick = async (category_name) => {
        setIsLoading(true);

        try {
            const response = await fetch(`${BASE_URLS}categories-blog?category=${category_name}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }

            const result = await response.json();

            console.log('result is: ', JSON.stringify(result, null, 1));

            setData(result);
            if (result.status == true) {
                setBlogs(result.Categories_Blog_Data)
            }
            else {
                setBlogs([])
            }


        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    }; console.log(data);

    function createMarkup(data) {
        return { __html: data };
    }

    return (

        <>

            {/* <Popper></Popper> */}

            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <main>
                <section id="cxg-blogs">
                    <div className="container">
                        <div className="cxg-outer">
                            <div className="cx-content-header cxg-blog-header">
                                <h2 className='mb-3 text-center'>Blogs:</h2>

                            </div>
                            {/* <div className='classification-blog'>
                                <ul>
                                <li ><a href='/blog' >All</a>
                                                </li>
                                    {
                                        cats.map((single) => {
                                            return (

                                                <li ><a href='#' onClick={()=>handleClick(single.category_name) }>{single.category_name}</a>
                                                </li>
                                               
                                               
                                            )
                                        })
                                    }
                                    
                                   
                                </ul>
                                
                            </div> */}

                            <div className="row">
                                {
                                    blogs.length != 0 ?
                                        blogs.slice(0, noofElement).map((single) => {
                                            return (
                                                <div key={single.slug} className="col-md-4">

                                                    <div className="cxg-blog-content">

                                                        <div className="cxg-blog-details">
                                                            <Link to={"/blog/" + single.slug}> <img src={single.title_image} alt='optional' />
                                                            <p
                                                                dangerouslySetInnerHTML={createMarkup(single.title)}

                                                            /><span className='date-para'>Publish Date:{single.date}</span></Link>

                                                            {/* <div className="cxg-btn-grp">
                                                           <Link to={"/blog_detail?id=" + single.id} className="cxg-blog-btn">Continue Reading<i className='bx bx-right-arrow-alt'></i></Link>
                                                       </div>  */}
                                                        </div>
                                                    </div>
                                                </div>

                                            )


                                        }) : <b className='text-center'>No blogs found</b>
                                }

                            </div>

                            {
                                blogs.length != 0 ?
                                    <div className="cxg-load-grp">
                                        <Link to="/blog" > <button type="button" className="cxg-load-btn">Explore More Blogs<i className='bx bx-right-arrow-alt'></i></button></Link>
                                    </div> : ""
                            }

                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default BlogHome;
