import React, { useState, useEffect, useContext } from 'react';

import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar'
import FooterEnsurance from './FooterEnsurance';
import MetaTags from 'react-meta-tags';
import { AppContext } from '../../user/AppContext';
import { TRACKING_CODE, BASE_URLS } from "./urls"
import { simpleGetCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
function About() {
    const [progress, setProgress] = useState(0)
    // const [data, setData] = useState([])
    // useEffect(() => {
    //     fetch("https://prod.yourDigiWill.com/web/DigiWill_teams").then((result) => {
    //         result.json().then((resp) => {
    //             console.warn("result", resp)
    //             setData(resp)
    //         })
    //     })
    // }, [])
    // console.warn(data)
    const [users, setUsers] = useState([])
    const [state, setState] = useState([])
    const [hasError, setHasError] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const getUser = async () => {
        simpleGetCallWithErrorResponse(ApiConfig.DigiWill_TEAM_ABOUT_PAGE).then((data) => {

            if (data.json.status) {
                setUsers(data.json.DigiWill_Teams);
            }

        })
            .catch((error) => {
                console.log("api response", error);
            });
    }

    useEffect(() => {
        getUser();
    }, [])



    return (

        <main>
            <MetaTags>
                <title>About Us | On a mission to curb unclaimed money in India</title>
                <meta name="description" content="We are India`s first & only asset claim guaranteeing company providing the platform where individuals can record & transfer their assets to their loved ones." />
            </MetaTags>

            {/* <Popper></Popper> */}


            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <section id="cxa-about">
                <div className="container">
                    <div className="cxa-outer">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="cxa-content text-justify">
                                    <h1 className='mb-4'>About Us</h1>

                                    <h3 className='mb-3'>DigiWill</h3>

                                    <h4 className='mb-3 head-cxa'>Aapki Virasat Aapke Apnko Ke Liye</h4>
                                    <h6 className='mb-3'>We have one mission, to ensure every family gets assets which are rightfully theirs, and nothing remains unclaimed. </h6>

                                    <p>India’s only DIGITAL VIRASAT solution. Enabling users to create tokenized + encrypted auto discovered financial & non-financial asset records, nominate your loved ones across all assets, investment & savings through one single platform, setup real-time or future event based communications of Wills & Records, declare asset distribution & update distribution of assets with just a few clicks & create an express will with 1 click or enable auto will creation.
                                        Additionally we serve your dependents and widowed with a doorstep handheld all asset claim service delivered through a dedicated service manager, with a choice of women service managers for kids and women in the family.</p>

                                    <p>DigiWill aims to become a household name for millions of families for inheritance and make the world a place where no family has to struggle for what is rightfully theirs and no assets remain unclaimed.</p>

                                    <p>Our personality can be defined by the Acronym C.R.E.D where C stands for Confidential, R stands for Real, E is for Empathetic, and D is for Dynamic. These four words are the cornerstone of our product, we value the secrecy of our users, and we have made a product that is in sync with the reality around it apart from that the idea behind the product comes from a deep emotional muse reflecting empathy and finally our product is intended to be ever-changing and ever-growing.</p>

                                    <p>Ajinkya thought of DigiWill as a young father who had started to worry about his daughter receiving what is rightfully hers like every parent does except, Ajinkya was ready to change the world for her and through that change the rules of financial inheritance, For Prateek he also had the same worries but in terms of his family's asset management. Prateek’s family is scattered around the world so it was only natural for him to come on board and add his expertise to the mix. They needed one last ingredient and that was a gifted tech co-founder, this is where Priya came in, a god-daughter to Ajinkya , whom he started curating at the age of 16 & mentored to achieve heights in a flash, and together the three musketeers started DigiWill.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 text-center">
                                <div >
                                    <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                    {/*    <a href="#"><img src="images/home/app_store.png" /></a> */}


                                </div>
                                <div className="cxa-content d-flex mt-5 mb-5" style={{ justifyContent: 'center' }}>

                                    <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/about_image.png" />

                                </div>

                                <div >
                                    <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                    {/*    <a href="#"><img src="images/home/app_store.png" /></a> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="cxa-team">
                <div className="container">
                    <div className="cxa-outer-team">
                        <h2>Meet the DigiWill Team</h2>
                        <div className="row">
                            {
                                users ? users.map((curElem) => {
                                    return (
                                        <>
                                            <div className="col-md-4 col-10 main-about-cards ">
                                                <div className="cxa-content cxa-content-team d-flex align-items-center">
                                                    <img src={curElem.profile_pic} style={{ borderRadius: '50%', width: "100px" }} />
                                                    <div className="cxa-team-in">
                                                        <div className="cxa-team-details">
                                                            <h4>{curElem.name}</h4>
                                                            <span>{curElem.post}</span>
                                                        </div>
                                                        <div className="cxa-team-social">
                                                            <a href={curElem.linkedin_link}><i className='bx bxl-linkedin-square' ></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    )
                                }) : ""

                            }












                        </div>
                    </div>
                </div>
            </section>

            <section id="cxp-download">
                <div className="container">
                    <div className="cxp-outer">
                        <div className="cxp-download-content">
                            <div className="cxp-download-btn">
                                {/* <a href="#" style={{marginRight:"8rem"}}><img src="./images/home/google_play.png" alt='optional' /></a> */}
                                {/*      <a href="#"><img src="./images/home/google_play.png" /></a> */}
                            </div>
                            <h4>Download India's Only One-Stop Financial & Non-Financial Asset Inheritance Solution.</h4>
                            <div >
                                <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                {/*    <a href="#"><img src="images/home/app_store.png" /></a> */}


                            </div>
                        </div>
                    </div>
                </div>
            </section>










            <FooterEnsurance />


        </main >

    )
}

export default About;
