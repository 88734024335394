import React, { useState, useEffect } from 'react';
import Popper from '../Popper';

import LoadingBar from 'react-top-loading-bar'
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
import YoutubeVideos from './YoutubeVideos';
import MetaTags from 'react-meta-tags';

ReactGA.initialize(TRACKING_CODE);
function Podcast() {
    useEffect(

        () => {
            setProgress(100)
        }, []
    )
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited Podcast page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = "Podcast - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [progress, setProgress] = useState(0)
    return (<div>
        <MetaTags>
            <title>Videos | Our Youtube Videos & Podcasts - DigiWill</title>
            <meta name="description" content="Checkout our product related videos & podcasts on fintech, mentorship and investments." />
        </MetaTags>


        {/* <Popper></Popper> */}
      
        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />

        <section id="cxp-blogs">
            <div className="container">
                <div className="cxp-outer">
                    <div className="cxp-content-header cxp-blog-header">
                        <h2>Podcast</h2>
                        <p>DigiWill Insiders</p>
                    </div>
                    <div className="cxp-podcasts">
                        {/* <h5>Podcast</h5> */}
                        <div className="row">
                            <div className="col-md-4">
                                <div className="cxp-blog-content">
                                    <iframe title='DigiWill' style={{ borderRadius: "12 px" }} src="https://open.spotify.com/embed/episode/2tVXXFDaWQg8VMDAdYGc9e?utm_source=generator" width="100%" height="232" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                                    {/* <div className="cxp-blog-details">
                                        <p>DigiWill – Why Should You Download This Application On Your Smartphone Right Now?</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="cxp-videos">
                    <h5>Videos</h5>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="cxp-blog-content">
                                <iframe  width="560" height="315" src="https://www.youtube.com/embed/G9kaso_ijmI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                <div className="cxp-blog-details">
                                    <p>DigiWill – Why Should You Download This Application On Your Smartphone Right Now?</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cxp-blog-content">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/G9kaso_ijmI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                <div className="cxp-blog-details">
                                    <p>DigiWill – Why Should You Download This Application On Your Smartphone Right Now?</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cxp-blog-content">
                                <iframe width="560" height="315"  src="https://www.youtube.com/embed/G9kaso_ijmI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                <div className="cxp-blog-details">
                                    <p>DigiWill – Why Should You Download This Application On Your Smartphone Right Now?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                   

                </div>
            </div>
        </section>

        <FooterEnsurance />




    </div>);
}

export default Podcast;
