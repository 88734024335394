import React, { useState, useEffect } from 'react';
import { BASE_URL } from './urls';
function Partnerslogo() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <section id="cx-partnerlogo" className=''>
                <div className="container">
                    <div className="cx-outer-partnerlogo">
                        <div className="cx-content-header cx-partner-header">
                            <h2 className='text-body'><span className='blink_me'>Recognized</span>  By:</h2>
                        </div>
                        <br></br>
                        <div class="container">
                            <div class="row align-items-center ">
                                <div class="container-fluid rounded">
                                    <div class="slider">
                                        <div class="logos">
                                            <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/awsscroll.png" alt="AWS Activate" />
                                            {/* <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/prda.png" alt=" Pension Fund Regulatory & Development Authority (PRDA)" /> */}
                                            {/* <img src="images/plogoscroll/sahamati.png" alt="" /> */}
                                            <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/tyke2.png" alt=" DigiWill Tyke Investments" />
                                            <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/startupindia.png" alt=" start up india" />
                                            <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/awsscroll.png" alt="AWS Activate" />
                                        {/* <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/prda.png" alt=" Pension Fund Regulatory & Development Authority (PRDA)" /> */}
                                            <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/tyke2.png" alt=" DigiWill Tyke Investments " />
                                            {/* <img src="images/plogoscroll/sahamati.png" alt="" /> */}
                                            <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/startupindia.png" alt="start up india" />
                                        </div>
                                        <div class="logos">
                                        <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/startupindia.png" alt="start up india" />
                                        <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/tyke2.png" alt=" DigiWill Tyke Investments " />
                                        <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/awsscroll.png" alt="AWS Activate" />
                                        {/* <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/prda.png" alt=" Pension Fund Regulatory & Development Authority (PRDA)" /> */}
                                            {/* <img src="images/plogoscroll/sahamati.png" alt="" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Partnerslogo;