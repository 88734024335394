import React, { useState, useEffect, useRef } from 'react';

import Popper from '../Popper';

import LoadingBar from 'react-top-loading-bar'
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
ReactGA.initialize(TRACKING_CODE);
function CurosityBox() {
    const ref = useRef(null);
    useEffect(() => {
        const btns = document.querySelectorAll(".acc-btn-home");
        function accordion() {
            // this = the btn | icon & bg changed
            this.classList.toggle("is-open");

            // the acc-content
            const content = this.nextElementSibling;

            // IF open, close | else open
            if (content.style.maxHeight) content.style.maxHeight = null;
            else content.style.maxHeight = content.scrollHeight + "px";
        }

        // event
        btns.forEach((el) => el.addEventListener("click", accordion));
    }, []);
    const [progress, setProgress] = useState(0)


    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited Curosity box page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(

        () => {
            setProgress(100)
        }, []
    )
    return (<div>



        {/* 
<Popper></Popper> */}


        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />

        <section id="faq-home">
            <div class="container">
                <div class="faq-wrapper-home ">
                    <div className="cx-content-header mb-5">
                        <h2 className='text-dark '>Frequently Answered Questions</h2>

                    </div>
                    <div class="d-flex flex-row flex-wrap">
                        <div class="col-md-12 col-lg-12 col-12 text-center ">
                            <main class="card2">

                                <div class="acc-container" ref={ref}>

                                    <button class="acc-btn-home panel-title" >
                                        What is DigiWill?
                                    </button>
                                    <div class="acc-content">
                                        <p>
                                            We are India`s first and only asset claim guaranteeing
                                            company providing the platform where individuals can record,
                                            maintain & transfer their assets to their loved ones hassle-free
                                            to ensure every family gets what’s theirs.
                                        </p>
                                    </div>

                                    <button class="acc-btn-home">
                                        What are the services that DigiWill offers?
                                    </button>
                                    <div class="acc-content">
                                        <p>
                                            Real-time communication with your family/nominees.
                                            A single, secure portal to streamline all financial records
                                            We offer a hassle-free wealth distribution and transfer  service

                                        </p>
                                    </div>

                                    <button class="acc-btn-home">
                                        How to join DigiWill?
                                    </button>
                                    <div class="acc-content">
                                        <p>
                                            You can download our app from the play store and start
                                            your financial inheritance journey with DigiWilll.
                                        </p>
                                    </div>

                                    <button class="acc-btn-home">
                                    What are the assets that you can add to DigiWill?
                                    </button>
                                    <div class="acc-content">
                                        <p>
                                        Financial Assets you can add to your DigiWill are
                                            investments, savings, earnings,  loans, credit cards, mutual funds,
                                            insurance, stocks/shares, real estate, gold/silver, diamond (physical),
                                            bonds, and non-financial assets
                                        </p>
                                    </div>

                                    <button class="acc-btn-home">
                                    What is the pricing structure for DigiWill services?
                                    </button>
                                    <div class="acc-content">
                                        <p>
                                        We have lifetime and yearly pricing that our users can choose from. Apart from that, you can also buy specific features according to your needs.
                                        </p>
                                    </div>

                                    <button class="acc-btn-home">
                                    What are the other services that DigiWill offers?
                                    </button>
                                    <div class="acc-content">
                                        <p>
                                        DigiWill also offers end-to-end claim support services.
                                        </p>
                                    </div>
                                </div>
                            </main>
                        </div>
                      
                    </div>


                </div>
            </div>
        </section>



    </div>);
}

export default CurosityBox;
