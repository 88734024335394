import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery';
const B2bAddSingalEmp = () => {
   
    useEffect(() => {
        $('.close-icon').on('click', function () {
            $(this).closest('.col').fadeOut();
        })
    });

    return (
        <>
            <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
                {/* <!-- Navbar Brand--> */}
                <a class="navbar-brand ps-3" href="index.html"><img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/whitee.png" alt="" style={{ width: "70px" }} /></a>
                {/* <!-- Sidebar Toggle--> */}
                <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i class="fas fa-bars"></i></button>
                {/* <!-- Navbar Search--> */}
                <form class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                    <div class="input-group">
                        <input class="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                        <button class="btn btn-primary" id="btnNavbarSearch" type="button"><i class="fas fa-search"></i></button>
                    </div>
                </form>
                {/* <!-- Navbar--> */}
                <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#!">Settings</a></li>
                            <li><a class="dropdown-item" href="#!">Activity Log</a></li>
                            <li><hr class="dropdown-divider" /></li>
                            <li><a class="dropdown-item" href="#!">Logout</a></li>
                        </ul>
                    </li>
                </ul>
            </nav>
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                        <div class="sb-sidenav-menu">
                            <div class="nav">
                                <div class="sb-sidenav-menu-heading">Core</div>
                                <a class="nav-link" href="#">
                                    <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                    Dashboard
                                </a>
                                <div class="sb-sidenav-menu-heading">Interface</div>
                                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                    <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                                    Layouts
                                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                </a>
                                <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                    <nav class="sb-sidenav-menu-nested nav">
                                        <a class="nav-link" href="layout-static.html">Static Navigation</a>
                                        <a class="nav-link" href="layout-sidenav-light.html">Light Sidenav</a>
                                    </nav>
                                </div>
                                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                                    <div class="sb-nav-link-icon"><i class="fas fa-book-open"></i></div>
                                    Pages
                                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                </a>
                                <div class="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                                    <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                                        <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">
                                            Authentication
                                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                        </a>
                                        <div class="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                            <nav class="sb-sidenav-menu-nested nav">
                                                <a class="nav-link" href="login.html">Login</a>
                                                <a class="nav-link" href="register.html">Register</a>
                                                <a class="nav-link" href="password.html">Forgot Password</a>
                                            </nav>
                                        </div>
                                        <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseError" aria-expanded="false" aria-controls="pagesCollapseError">
                                            Error
                                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                        </a>
                                        <div class="collapse" id="pagesCollapseError" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                            <nav class="sb-sidenav-menu-nested nav">
                                                <a class="nav-link" href="401.html">401 Page</a>
                                                <a class="nav-link" href="404.html">404 Page</a>
                                                <a class="nav-link" href="500.html">500 Page</a>
                                            </nav>
                                        </div>
                                    </nav>
                                </div>
                                <div class="sb-sidenav-menu-heading">Addons</div>
                                <a class="nav-link" href="charts.html">
                                    <div class="sb-nav-link-icon"><i class="fas fa-chart-area"></i></div>
                                    Charts
                                </a>
                                <a class="nav-link" href="tables.html">
                                    <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
                                    Tables
                                </a>
                            </div>
                        </div>
                        <div class="sb-sidenav-footer">
                            <div class="small">Logged in as:</div>
                            Start Bootstrap
                        </div>
                    </nav>
                </div>
                <div id="layoutSidenav_content">
                    <main>
                        <div class="container-fluid px-4">
                            <h1 class="mt-4">Enter Company details</h1>
                            <ol class="breadcrumb mb-4">
                                <li class="breadcrumb-item active">Dashboard</li>
                            </ol>
                            {/* add employee card wrapper */}
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <Link to="/business-page-single-emp-add"><div >
                                        <div class="card text-center ">
                                            <div class="card-body">
                                                <i class="bi bi-plus-circle fa-2x text-success "></i>
                                                <h5 class="card-title text-success">Add Employee</h5>
                                                <p class="card-text">Add Individual Employee details</p>

                                            </div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className='col-lg-6'>
                                    <Link to="/business-page-multiple-emp-add">
                                        <div >
                                            <div class="card text-center">
                                                <div class="card-body">
                                                    <i class="bi bi-plus-circle fa-2x text-success"></i>
                                                    <h5 class="card-title text-success">Bulk Upload</h5>
                                                    <p class="card-text">Add CSV file to add details in Bulk</p>

                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            {/* button click single and multiple employee adding wrapper  */}
                            <div className='row'>
                                <div className='col-lg-12 border mt-5 mb-4' >

                                    <div className="row mt-1 mb-4">
                                        <h4 className='mt-3'>Employees Information</h4 >
                                        <form class="row g-3 needs-validation" novalidate>
                                            <div class="col-md-6 mb-2">
                                                <label class="form-label">Name:</label>
                                                <input type="text" class="form-control" required />

                                            </div>
                                            <div class="col-md-6  mb-2">
                                                <label for="validationCustom02" class="form-label">Employee ID:</label>
                                                <input type="text" class="form-control" value="Otto" required />

                                            </div>
                                            <div class="col-md-6  mb-2">
                                                <label class="form-label">Email:</label>
                                                <input type="text" class="form-control" required />

                                            </div>
                                            <div class="col-md-6  mb-2">
                                                <label class="form-label">Mobile Number:</label>
                                                <input type="text" class="form-control" required />

                                            </div>
                                            <div class="col-md-6  mb-2">
                                                <label class="form-label">Aadhar Number:</label>
                                                <input type="text" class="form-control" required />
                                            </div>

                                            <div class="col-12 ">
                                                <button class="btn btn-success" type="submit">Submit form</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='row border mt-4'>
                                        <div className='col-lg-6'>
                                            <h6 className='mt-4'>Employees Details, Proceed with Payments below Once done adding Employees</h6>
                                        </div>
                                        <div className='col-lg-6 d-flex flex-column align-items-end mb-4 px-3'>
                                            <h4 className='mt-4 text-success'>$5,000</h4>
                                            <p className='text-success'>For 3/ Employees</p>
                                            <button className='btn btn-success'>Proceed to payments</button>
                                        </div>
                                    </div>


                                </div>

                            </div>
                            {/* single emp add information cards */}
                           
                            <div class="row row-cols-1 row-cols-md-3 g-4 mb-5">
                                <div class="col">
                                    <div class="card">
                                    <span class="pull-left clickable close-icon d-flex justify-content-end  px-3 py-2" data-effect="fadeOut"><i class="fa-solid fa-trash-can"></i></span>
                                        <div class="card-body">
                                        <h5 class="card-title text-success">Shantanu Naidue</h5>
                                            <div>#22113</div>
                                            <div>shantanu@gmail.com</div>
                                            <div>912345678990</div>
                                            <div>ADHAR2213444</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card">
                                    <span class="pull-right clickable close-icon d-flex justify-content-end px-3 py-2" data-effect="fadeOut"><i class="fa-solid fa-trash-can"></i></span>
                                        <div class="card-body">
                                        <h5 class="card-title text-success">Komal Patel</h5>
                                        <div>#22113</div>
                                            <div>komalP@gmail.com</div>
                                            <div>912345678990</div>
                                            <div>ADHAR2213444</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card">
                                    <span class="pull-right clickable close-icon d-flex justify-content-end  px-3 py-2" data-effect="fadeOut"><i class="fa-solid fa-trash-can"></i></span>
                                        <div class="card-body ">
                                            <h5 class="card-title text-success">Ss Suyog</h5>
                                            <div>#23113</div>
                                            <div>shantanu@gmail.com</div>
                                            <div>912345678990</div>
                                            <div>ADHAR2213444</div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </main>

                </div>
            </div>
        </>
    )
}

export default B2bAddSingalEmp
