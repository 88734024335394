import React, { useEffect, useState, useNavigate, useContext } from 'react';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import ReactGA, { set } from 'react-ga';
import { TRACKING_CODE } from '../pages/urls';
import LoadingBar from 'react-top-loading-bar';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { AppContext } from '../../user/AppContext';
import ApiConfig from '../../api/ApiConfig';
import { PostCallWithErrorResponse } from '../../api/ApiServices';
import ReCAPTCHA from "react-google-recaptcha";
import swal from 'sweetalert';
ReactGA.initialize(TRACKING_CODE);
function B2bHomeScreen() {
    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext);
    const [progress, setProgress] = useState(0)
    const alert = useAlert()
    const [registerData, setRegisterData] = useState({ name: "", email: "", mobile_no: "", team_size: "", company_name: "" })
    //const [success,setsuccess]=useState(false)
    const [verifed, setVerifed] = useState(false);
    function onChange(value) {
        console.log("Captcha value:", value);
        setVerifed(true);
    }
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited b2b page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])


    useEffect(
        () => {
            setProgress(100)
            document.title = "Business page comppany info"
        }, []
    )
    const handleRegister = async (e) => {
        e.preventDefault()
        // alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })
        swal({
            title: "Thank You",
            text: "Our representative will contact you in 24 hours and discuss the process further \n For any queries contact - 91234567899 " ,
            icon: "https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/whitee.png",
            button: "Okay",
            className: "swal-b2b"
          });
        PostCallWithErrorResponse(ApiConfig.B2BPAGE, registerData).then((data) => {
            console.log(data.json)
            if (data.json.status) {
                // console.log("first")
                setRegisterData({ name: "", email: "", mobile_no: "", team_size: "", company_name: "" })
                setTimeout(() => {
                    setProgress(25)
                }, 100);
                setTimeout(() => {
                    setProgress(70)
                }, 100);
                setTimeout(() => {
                    setProgress(100)
                }, 100);
            }
        })
            .catch((error) => {
                console.log("api response", error);
            });
        ReactGA.event({
            category: 'User',
            action: 'b2b contact form'
        });


    }

    const handleChange = (e) => {

        if (e.target.name === 'name') {

            setRegisterData({ ...registerData, name: e.target.value })
        }

        if (e.target.name === 'email') {

            setRegisterData({ ...registerData, email: e.target.value })
        }

         if (e.target.name === 'mobile_no') {

             setRegisterData({ ...registerData, mobile_no: e.target.value })
         }

        if (e.target.name === 'company_name') {

            setRegisterData({ ...registerData, company_name: e.target.value })
        }
        if (e.target.name === 'team_size') {

            setRegisterData({ ...registerData, team_size: e.target.value })
        }

    }

    return <div>
        <MetaTags>
            <title></title>
            <meta name="description" content=" " />
        </MetaTags>
        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />
        <section id="cx-b2b-get" className='' >
            <div className="container">
                <div className="cx-outer-b2b">
                    <div className="cx-content-header">
                        <h2 className='mb-5'></h2>
                    </div>
                    <div className="row">

                        <div className="col-lg-5">
                            <div className='cx-b2b-right-wrapper'>
                                <div>
                                    <h3 className='mb-3'>Gift your team great<br /> Legal Will benefits </h3>
                                </div>
                                <div className='b2b-get-in-form'>
                                    <form onSubmit={(e) => handleRegister(e)} name="myForm">
                                        <div className="mb-3">
                                            <input type="text" pattern="^[A-Za-z -]+$" maxlength="40" required value={registerData.name} onChange={(e) => handleChange(e)} name='name' className="form-control1" id="exampleInputName" placeholder='Your Name*' />
                                        </div>

                                        <div className="mb-3">
                                            <input type="email" required value={registerData.email} onChange={(e) => handleChange(e)} name='email' className="form-control1" id="exampleInputEmail1" placeholder='Your Work Email*' />
                                        </div>
                                        <div class="mb-3">
                                            <input type="text" pattern="^[A-Za-z -]+$" maxlength="120" required value={registerData.company_name} onChange={(e) => handleChange(e)} name='company_name' className="form-control1" id="exampleInputName" placeholder='Company name*' />
                                        </div>
                                        <div className="mb-3">
                                            <input type="tel" pattern="[0-9]{10}" required value={registerData.mobile_no} onChange={(e) => handleChange(e)} name='mobile_no' className="form-control1" id="exampleInputEmail1" placeholder="Phone No" />
                                        </div>
                                        <div class="mb-3">
                                            <input name='team_size' required value={registerData.team_size} onChange={(e) => handleChange(e)}  className="form-control1" id="exampleInputName" placeholder='Team Size*' />
                                        </div>
                                        <div className="captcha" style={{ transform: "scale(0.80)", transformOrigin: "0 0" }}>
                                            <ReCAPTCHA
                                                sitekey="6LfPk6MjAAAAAILoHwcOKf-55xEIYFxc9tyWQzsw"
                                                onChange={onChange}
                                            />
                                        </div>
                                        <button type="submit" className=" btn btn-success contact-btn" disabled={!verifed}>Get Quote</button>
                                        <div class="mb-3 mt-3 d-flex flex-row justify-content-center ">
                                            <span className='cx-b2b-form-footer-span'>Our representative will contact you for further process.</span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4" >
                            <div className=" cx-b2b-left-wrapper">
                                <div className=''>
                                    <img src="./images/b2b/homepage.png" alt="contact-get-in-touch" />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 mb-5'>
                            <div class="card b2b-card-right" >
                                <div class="card-body">
                                    <img src="./images/b2b/smallimg.png" alt="" />
                                    <h6 >Need Help With Something?</h6>
                                    <p className='mt-3 mb-3'>Request Call back , sit back Relax!</p>
                                    <h6 className='mt-3 mb-3 fs-5' style={{ color: "#05A081" }}>Talk to our Experts</h6>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='b2b-extra-information' >

        </section>

        <section id="cxc-cards">
            <div className='container'>
                <div className='cxc-outer-cards'>
                    <div className="cx-content-header">
                        <h2 className='mb-5 text-dark'>Benefits of DigiWill to Employees</h2>
                    </div>
                    <div className='row'>
                        <div class="col-lg-3">
                            <div class=" p-2 ">
                                <div class="cx-card-block mb-4">
                                    <img alt="" class="img-fluid" src="./images/b2b/smallimg.png" style={{ height: "10vh" }} />
                                    <h4 class="my-3" editable="inline">Asset Discovery</h4>
                                    <p editable="inline">Auto Discovered Financial & Non-Financial Asset Records, Unlimited Custom Asset Record Creation.Monthly Auto updates of Assets.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <div class=" p-2 ">
                                <div class="cx-card-block mb-4">
                                    <img alt="" class="img-fluid" src="./images/b2b/smallimg.png" style={{ height: "10vh" }} />

                                    <h4 class="my-3" editable="inline">Communication<br /> of Records</h4>
                                    <p editable="inline">Human Interference free Real Time & Ai powered future event based communication of financial records & wills. Custom Event Communication </p>


                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <div class=" p-2 ">
                                <div class="cx-card-block mb-4">
                                    <img alt="" class="img-fluid" src="./images/b2b/smallimg.png" style={{ height: "10vh" }} />

                                    <h4 class="my-3" editable="inline">Central Nomination</h4>
                                    <p editable="inline">Nominee Management & Assignment of single or multiple nominees across all assets.</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class=" p-2 ">
                                <div class="cx-card-block mb-4">
                                    <img alt="" class="img-fluid" src="./images/b2b/smallimg.png" style={{ height: "10vh" }} />

                                    <h4 class="my-3" editable="inline">Asset Distribution</h4>
                                    <p editable="inline">Dynamic Distribution & Re-distribution of assets using their phone.</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class=" p-2 ">
                                <div class="cx-card-block mb-4">
                                    <img alt="" class="img-fluid" src="./images/b2b/smallimg.png" style={{ height: "10vh" }} />

                                    <h4 class="my-3" editable="inline">1 Click<br /> Will Creation</h4>
                                    <p editable="inline">Create a will with 1 click
                                        Aadhar Esign Stamping
                                        Facial Stamping
                                        Auto-Will Creation</p>

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <div class=" p-2 ">
                                <div class="cx-card-block mb-4">
                                    <img alt="" class="img-fluid" src="./images/b2b/smallimg.png" style={{ height: "12vh" }} />

                                    <h4 class="my-3" editable="inline">Guaranteed Security</h4>
                                    <p editable="inline">Activity/Change Video Logs Tokenized + Encrypted Records 256 Bit AES Encryption Sensitive Information Masking</p>

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <div class=" p-2 ">
                                <div class="cx-card-block mb-4">
                                    <img alt="" class="img-fluid" src="./images/b2b/smallimg.png" style={{ height: "12vh" }} />

                                    <h4 class="my-3" editable="inline">Life Status Tracking Ai</h4>
                                    <p editable="inline">In house built Life Status Tracking Artificial Intelligence provides option to maintain secrecy and sharing of records after only after ones demise.</p>


                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class=" p-2 ">
                                <div class="cx-card-block mb-4">
                                    <img alt="" class="img-fluid" src="./images/b2b/smallimg.png" style={{ height: "12vh" }} />

                                    <h4 class="my-3" editable="inline">Claim it</h4>
                                    <p editable="inline">An all asset hand-held doorstep claim service for loved ones, & nominees after their demise. Complimentary Legal & Financial Advisory</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    </div>;
}

export default B2bHomeScreen;
