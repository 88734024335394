import React, { useContext, useEffect, useState } from 'react';
import { TRACKING_CODE, BASE_URLS } from "./urls"
import { Routes, Route, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar'
import FooterEnsurance from './FooterEnsurance';
import Podcast from './Podcast'
import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags';
import { AppContext } from '../../user/AppContext';
import { simpleGetCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
ReactGA.initialize(TRACKING_CODE);
function Blog() {
    const width = window.innerWidth
    const [blogs, setBlogs] = useState([])
    const [cats, setcats] = useState([])
    const [catsearch, setcatsearch] = useState([])
    const [noofElement, setnoofElement] = useState(6)
    // const [cat,setCat]=useState([])
    const [data, setData] = useState({ data: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');
    // const {slug}=params
    // console.log("hie params",params)
    const [progress, setProgress] = useState(0)
    const { show, setShow } = useContext(AppContext)
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited blog page'
        });
        //   document.title="Blog - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])
    const loadmore = () => {
        setnoofElement(noofElement + 3)
        setProgress(100)
    }
    const loadAllBlogs = async () => {

        simpleGetCallWithErrorResponse(ApiConfig.BLOG_DISPLAY).then((data) => {

            if (data.json.status) {
                setBlogs(data.json.Blog_Data);
            }

        })
            .catch((error) => {
                console.log("api response", error);
            });


    }

    useEffect(() => {
        loadAllBlogs();
        simpleGetCallWithErrorResponse(ApiConfig.BLOG_CATEGORY).then((data) => {

            if (data.json.status) {
                setcats(data.json.Category);
            }

        })
            .catch((error) => {
                console.log("api response", error);
            });

    }, [])

    //cataory blog data show in another no 3 api
    const handleClick = async (category_name) => {
        setIsLoading(true);
        simpleGetCallWithErrorResponse(ApiConfig.BLOG_CATEGORY_SEARCH + `?category=${category_name}`).then((data) => {

            const result = data.json
            console.log(result)
            setData(result);
            if (result.status) {
                setBlogs(result.Categories_Blog_Data)
            }
            else {
                setBlogs([])
            }

        })
            .catch((error) => {
                console.log("api response", error);
            });



    }; console.log(data);

    function createMarkup(data) {
        return { __html: data };
    }

    return (


        <div>
            <MetaTags>
                <title>Blogs | Read our latest Blogs on Personal Finance</title>
                <meta name="description" content="Check Out our latest blogs on how to manage different types of investments, savings & liabilities." />
            </MetaTags>


            {/* <Popper></Popper> */}

            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <main>
                <section id="cxg-blogs">
                    <div className="container">
                        <div className="cxg-outer">
                            <div className="cxg-content-header cxg-blog-header">
                                <h1 className='mb-3'>Blogs</h1>
                                <h3 className='mb-4'>Fintech Insights</h3>
                            </div>
                            <div className='classification-blog'>
                                <ul>
                                    <li ><Link to='/blog' onClick={() => loadAllBlogs()} >All</Link>
                                    </li>
                                    {
                                        cats.map((single) => {
                                            return (

                                                <li ><a href='#' onClick={() => handleClick(single.category_name)}>{single.category_name}</a>
                                                </li>

                                            )
                                        })
                                    }


                                </ul>

                            </div>

                            <div className="row">
                                {
                                    blogs.length != 0 ?
                                        blogs.slice(0, noofElement).map((single) => {
                                            return (
                                                <div key={single.slug} className="col-md-4">

                                                    <div className="cxg-blog-content">

                                                        <div className="cxg-blog-details">
                                                            <Link to={"/blog/" + single.slug}> <img src={single.title_image} alt='optional' /><p
                                                                dangerouslySetInnerHTML={createMarkup(single.title)}

                                                            /><span className='date-para'>Publish Date:{single.date}</span></Link>

                                                            {/* <div className="cxg-btn-grp">
                                                            <Link to={"/blog_detail?id=" + single.id} className="cxg-blog-btn">Continue Reading<i className='bx bx-right-arrow-alt'></i></Link>
                                                        </div>  */}
                                                        </div>
                                                    </div>
                                                </div>

                                            )


                                        }) : <b className='text-center'>No blogs found</b>
                                }

                            </div>

                            {
                                blogs.length != 0 ?
                                    <div className="cxg-load-grp">
                                        <button type="button" onClick={() => loadmore()} className="cxg-load-btn">Load More <i className='bx bx-down-arrow-alt'></i></button>
                                    </div> : ""
                            }

                        </div>
                    </div>
                </section>

                <section id="cxp-download">
                    <div className="container">
                        <div className="cxp-outer">
                            <div className="cxp-download-content">
                                <div className="cxp-download-btn">
                                    {/* <a href="#" style={{marginRight:"8rem"}}><img src="./images/home/google_play.png" alt='optional' /></a> */}
                                    {/*      <a href="#"><img src="./images/home/google_play.png" /></a> */}
                                </div>
                                <h4>Download India's Only One-Stop Financial & Non-Financial Asset Inheritance Solution.</h4>
                                <div >
                                        <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png"  style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                            {/*    <a href="#"><img src="images/home/app_store.png" /></a> */}
                                        
                    
                                        </div>
                            </div>
                        </div>
                    </div>
                </section>


                <FooterEnsurance></FooterEnsurance>



            </main>
        </div>
    )
}

export default Blog;
