import React, { useState, useEffect } from 'react';

import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar'
import { PLAYSTORE_URL } from './urls';
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
import MetaTags from 'react-meta-tags';
ReactGA.initialize(TRACKING_CODE);
function Hotdeals() {

    const [progress, setProgress] = useState(0)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(

        () => {
            setProgress(100)
        }, []
    )
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited Hotdeals page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = "Hotdeals - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])
    return (<div>
        <MetaTags>
            <title>Hot Deals | Platform for innovative Financial Products</title>
            <meta name="description" content="DigiWill users can now get their hands on exclusive financial products from startups before anyone in the market." />
        </MetaTags>



        {/* <Popper></Popper> */}
   

        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />
        <section id="cxh-hero">
            <div className="cxh-hero-o d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="cxh-hero-content">
                                <h2>Hassle-free doorstep assistance for
                                    you and your loved ones.</h2>
                                <button type="button" className="cx-btn">Coming Soon</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>




        <section id="cxh-ivest">
            <div className="cxh-outer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cxh-invest-content cxh-bg-01">
                                <span>Hot Deals</span>
                                <h2>For Brands...</h2>
                                <p>Hot Deal gives a platform to multiple fintech brands and startups where they can launch their new products whilst having exclusive and early access to DigiWill users. </p>
                                <div className="cxh-invest-img">
                                    <img src="./images/invest/coming_soon.png" alt="invest" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="cxh-invest-content cxh-bg-02">
                                <span>Hot Deals</span>
                                <h2>For Users...</h2>
                                <p>DigiWill users can now get their hands on exclusive financial products from startups before anyone in the market, providing them with an early opportunity.</p>
                                <div className="cxh-invest-img">
                                    <img src="./images/invest/coming_soon.png" alt="invest" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>





        <section id="cxh-download">
            <div className="container">
                <div className="cxh-outer" style={{ paddingTop: 0 }}>
                    <div className="cxh-download-content">
                        <div className="cxh-download-btn">
                            {/* <a href={PLAYSTORE_URL} style={{marginRight:"8 rem"}}><img src="./images/home/google_play.png"  alt='optional'/></a> */}
                            {/*     <a href="#"><img src="./images/home/google_play.png" /></a> */}
                        </div>
                        <h4>Download the most secure online document storage app for android and iOS devices now!</h4>
                    </div>
                </div>
            </div>
        </section>


        <FooterEnsurance />



    </div>);
}

export default Hotdeals;
