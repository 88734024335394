
import React, { useState, useEffect } from 'react';
import Popper from '../Popper';
import LoadingBar from 'react-top-loading-bar';
import { Link } from 'react-router-dom';
import { PLAYSTORE_URL } from './urls';
import FooterEnsurance from './FooterEnsurance';
import ReactGA from 'react-ga';
import { TRACKING_CODE } from './urls';
import MetaTags from 'react-meta-tags';
ReactGA.initialize(TRACKING_CODE);
function DistributionInfo() {
    const [progress, setProgress] = useState(0)

    useEffect(

        () => {
            setProgress(100)
        }, []
    )
    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'visited Invest page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = "Invest - DigiWill-Keep The Track Record of Your Assets and Liabilities"
    }, [])
    return (<div>
        {/* <MetaTags>
            <title> Invest in | A One-Stop Financial Market Place - DigiWill</title>
            <meta name="description" content="Invest, record, grow, and transmit all your finances all at one place on the go with Invest in Feature from DigiWill." />
        </MetaTags> */}

        {/* 
          <Popper></Popper> */}

        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />

                <section id="distiInfo-wrapper" className='' >
                    <div className="container">
                        <div className="cx-outer-disti">
                            <div className="cx-content-header">
                                <h2 className='mb-2 float-start text-justify'>Interested In<br /> Distributing DigiWill?</h2>
                            </div>
                        </div>
                        <div class="row row-cols-1 row-cols-md-3 g-4 col-lg-9 col-12 ">
                            <div class="col">
                                <div class="card distribution-card">
                                    <div class="card-body d-flex flex-column justify-content-between">
                                        <img src="./images/distribution/card1.png" alt='Secure'/>
                                        <h5 class="card-title">Secure your employees & their families</h5>
                                        <Link to="./business-page"><span>know more...</span></Link> 

                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card distribution-card">

                                    <div class="card-body d-flex flex-column justify-content-between ">
                                        <img src="./images/distribution/card2.png" alt='Become a channel partner' />
                                        <h5 class="card-title">Become a Channel Partner</h5>
                                        <Link to="./channel-partner"><span>know more...</span></Link> 
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card distribution-card">
                                    <div class="card-body d-flex flex-column justify-content-between">
                                        <img src="./images/distribution/card3.png" alt='Become a service partner' />
                                        <h5 class="card-title">Become a Service Partner</h5>
                                       <Link to="./service-partner"><span>know more...</span></Link> 

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
          
       



    </div>);
}

export default DistributionInfo;
