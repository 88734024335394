import React, { useEffect, useRef, useState, useContext } from 'react';
import Popper from '../Popper';
import { BASE_URLS, PLAYSTORE_URL } from './urls';
import { useAlert } from 'react-alert'
import { positions } from 'react-alert'
import ReactGA, { event } from 'react-ga';
import { TRACKING_CODE } from './urls';
import LoadingBar from 'react-top-loading-bar';
import Multiselect from 'multiselect-react-dropdown';
import MetaTags from 'react-meta-tags';
import { default as ReactSelect } from "react-select";
import { AppContext } from '../../user/AppContext';
import { PostCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
import input from 'multiselect-react-dropdown';
import swal from 'sweetalert';
import ReCAPTCHA from "react-google-recaptcha";
ReactGA.initialize(TRACKING_CODE);

function Claim() {

    const width = window.innerWidth
    const { show, setShow } = useContext(AppContext)
    const [verifed, setVerifed] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerifed(true);
    }
    useEffect(() => {

        if (width <= 972) {
            setShow('none')
        }
        else {
            setShow('flex')
        }

    }, [])
    const resetref = useRef()
    useEffect(() => {
        ReactGA.event({
            category: 'claim_it',
            action: 'visited claim service page'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);


    }, [])
    const [progress, setProgress] = useState(0)
    const alert = useAlert()
    const [registerData, setRegisterData] = useState({ first_name: '', last_name: '', email: "", mobile_no: "", city: "", message: "" })
    const [selectedata, setselecteddata] = useState([])

    useEffect(
        () => {

            setProgress(100)
            document.title = "claim-it page - DigiWill-Keep The Track Record of Your Assets and Liabilities"
        }, []
    )
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleChange = (e) => {

        if (e.target.name === 'first_name') {

            setRegisterData({ ...registerData, first_name: e.target.value })
        }

        if (e.target.name === 'last_name') {

            setRegisterData({ ...registerData, last_name: e.target.value })
        }

        if (e.target.name === 'email') {

            setRegisterData({ ...registerData, email: e.target.value })
        }

        if (e.target.name === 'mobile_no') {

            setRegisterData({ ...registerData, mobile_no: e.target.value })
        }

        if (e.target.name === 'city') {

            setRegisterData({ ...registerData, city: e.target.value })
        }
        // if (e.target.name === 'service') {

        //     setRegisterData({ ...registerData, service: e.target.value })
        // }
        if (e.target.name === 'message') {

            setRegisterData({ ...registerData, message: e.target.value })
        }

    }

    const handleRegister = async (e) => {
        e.preventDefault()
        //e.target.reset();
        if (selectedata.length == 0) {
            swal({
                title: "Services Field Is Required. ",
                icon: "warning",
                button: "OK",
            });
            //alert.show('ve will contact you shortly..')
            return
        }
        alert.show('Hi thank you for choosing us ! Our Representative will contact you shortly..', { position: positions.TOP_CENTER })

        PostCallWithErrorResponse(ApiConfig.CLAIM_IT_PAGE, { ...registerData, service: selectedata }).then((data) => {
            console.log(data.json)
            if (data.json.status) {
                console.log("first")
                setselecteddata([])
                resetref.current.resetSelectedValues();
                setRegisterData({ first_name: '', last_name: '', email: "", mobile_no: "", city: "", service: "", message: "" })
                setTimeout(() => {
                    setProgress(25)
                }, 100);
                setTimeout(() => {
                    setProgress(70)
                }, 100);
                setTimeout(() => {
                    setProgress(100)
                }, 100);
            }
        })
            .catch((error) => {
                console.log("api response", error);
            });
        ReactGA.event({
            category: 'User',
            action: 'claim-it form submitted'
        });

    }

    const servicedata = [
        {
            cat: 'Property Claims',
            key: 'Land Assets/Property ownership transfer'
        },
        {
            cat: 'Property Claims',
            key: '7/12'
        },
        {
            cat: 'Property Claims',
            key: 'Property card'
        },
        {
            cat: 'Property Claims',
            key: 'City Survey'
        },
        {
            cat: 'Property Claims',
            key: 'Varas Nondani'
        },
        {
            cat: 'Property Claims',
            key: 'Tax Receipt'
        },
        {
            cat: 'Property Claims',
            key: 'MSEB Bill Name Change.'
        },
        {
            cat: 'Property Claims',
            key: 'Water Bill Name Change'
        },

        {
            cat: 'Heir Registry',
            key: 'Heir Registry'
        },
        {
            cat: 'Investment Claims',
            key: 'Bank Investment Claims'
        },
        {
            cat: 'Investment Claims',
            key: 'Investments/savings/earnings'
        },
        {
            cat: 'Investment Claims',
            key: 'Mutual Fund Claims'
        },
        {
            cat: 'Investment Claims',
            key: 'Insurance Claims'
        },
        {
            cat: 'Investment Claims',
            key: 'Stocks/Bonds/NFTs'
        },
        {
            cat: 'Investment Claims',
            key: 'Post Office Investment Claims'
        }
    ]

    const [options] = useState(servicedata);

    const handleSelect = (selectedList, selectedItem) => {

        setselecteddata(selectedList)
    }
    function handleRemove(selectedList, selectedItem) {

        setselecteddata(selectedList)

    }

    return (
        <div>
            <MetaTags>
                <title>DigiWill Claim It Services | Get Complete Claim Support</title>
                <meta name="description" content="Get complete claim support for your property, investments and insurance. We provide complete claim support." />
            </MetaTags>
            {/* <Popper></Popper> */}

            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <main>

                <section id="cxp-hero">
                    <div className="cxp-hero-c d-flex align-items-center">
                        <div className="container">
                            <div className="row d-flex flex-column">
                                <div className="col-lg-6  col-md-6 co-12">
                                    <div className="cxp-hero-content-claim ">
                                        <h2 className='cxp-hero-text mb-4 '>Claim it!<br />From your home.</h2>
                                        <p className='cxp-hero-text '>We provide complete claim support you dont even<br /> have to step outside from your home.</p>
                                    </div>
                                </div>
                                <div className="col-lg-12  col-md-6 col-12 ">
                                    <div className="cxp-entails-content">
                                        <div className="cxp-eform-cm mt-4">
                                            <h2 className='mb-4'>Claim It!</h2>
                                            <form onSubmit={(e) => handleRegister(e)} name="myForm" >
                                                <div className="row">
                                                    <div className="mb-3 col">
                                                        <label className='mb-1'>First Name</label>
                                                        <input type="text" pattern="[A-Za-z]*" required value={registerData.first_name} onChange={(e) => handleChange(e)} name='first_name' className="form-control " id="exampleInputEmail1" placeholder="" />
                                                    </div>
                                                    <div className="mb-3 col">
                                                        <label className='mb-1'>Last Name</label>
                                                        <input type="text" pattern="[A-Za-z]*" required value={registerData.last_name} onChange={(e) => handleChange(e)} name='last_name' className="form-control" id="exampleInputEmail1" placeholder="" />
                                                    </div>
                                                    <div className="mb-3 col">
                                                        <label className='mb-1'>Email</label>
                                                        <input type="email" required value={registerData.email} onChange={(e) => handleChange(e)} name='email' className="form-control" id="exampleInputEmail1" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className="mb-3 col">
                                                        <label className='mb-1'>Mobile</label>
                                                        <input type="tel" oninvalid="this.setCustomValidity('User ID is a must')" pattern="[0-9]{10}" required value={registerData.mobile_no} onChange={(e) => handleChange(e)} name='mobile_no' className="form-control" id="exampleInputEmail1" placeholder="" />
                                                    </div>
                                                    <div className="mb-3 col">
                                                        <label className='mb-1'>City</label>
                                                        <input type="text" pattern="[A-Za-z]*" required value={registerData.city} onChange={(e) => handleChange(e)} name='city' className="form-control" id="exampleInputEmail1" placeholder="" />
                                                    </div>
                                                    <div className="mb-3 col">
                                                        <label className='mb-1'>Services</label>
                                                        <Multiselect
                                                            ref={resetref}
                                                            value={selectedata} name='service'
                                                            displayValue="key"
                                                            groupBy="cat"
                                                            onRemove={handleRemove}
                                                            onSelect={handleSelect}
                                                            options={servicedata}

                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="mb-3 d-flex flex-column  col">
                                                        <label className='mb-1 '>Message</label>
                                                        <textarea id="textarea" required pattern="[A-Za-z]*" minlenght="12" maxlength="300" value={registerData.message} onChange={(e) => handleChange(e)} name='message' placeholder="" rows="2"></textarea>
                                                    </div>
                                                    <div className="mb-3 col">
                                                        <div className="captcha mt-4" style={{ transform: "scale(0.88)", transformOrigin: "0 0" }}>
                                                            <ReCAPTCHA
                                                                sitekey="6LfPk6MjAAAAAILoHwcOKf-55xEIYFxc9tyWQzsw"
                                                                onChange={onChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 d-flex flex-column col">

                                                    </div>
                                                </div>
                                                <div className='mb-4 mt-2 me-auto ms-auto w-25 submit-btn-contain '>
                                                    <button type="submit" className="cx-btn" disabled={!verifed}>Request Quote</button>
                                                </div>


                                                {/* <div className="row flex-column">
                                                    <div className="mb-4 me-auto  w-25 textarea-contain col">
                                                        <label className='mb-1 '>Message</label>
                                                        <textarea id="textarea" required pattern="[A-Za-z]*" minlenght="12" maxlength="300" value={registerData.message} onChange={(e) => handleChange(e)} name='message' placeholder="" rows="2"></textarea>
                                                    </div>
                                                    <div className="captcha" style={{ transform: "scale(0.88)", transformOrigin: "0 0" }}>
                                                        <ReCAPTCHA
                                                            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                    <div className='mb-5 me-auto ms-auto w-25 submit-btn-contain '>
                                                        <button type="submit" className="cx-btn ">Request Quote</button>
                                                    </div>
                                                </div> */}


                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section id="cxp-claim-card">
                    <div className="container">
                        <div className="cx-outer-footer mb-5">
                            <div className="row">
                                <div className='col-lg-4'>
                                    <div class="card p-2">
                                        <div class="card-body mb-3">
                                            <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/comm.png" alt="Communication" />
                                            <h6 class="card-subtitle mb-4 ">Communication</h6>
                                            <p class="card-text">You will receive a call and your issues will be addressed in this stage. </p>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div class="card p-2">
                                        <div class="card-body mb-3">
                                            <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/doc.png" alt="Document Sharing" />
                                            <h6 class="card-subtitle mb-4 ">Document Sharing</h6>
                                            <p class="card-text">In this stage you  will share the documents needed.</p>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div class="card p-2">
                                        <div class="card-body mb-3">
                                            <img src="https://websiteimagesdigiwill1.s3.ap-south-1.amazonaws.com/relax.png" alt="Relax" />
                                            <h6 class="card-subtitle mb-4 ">Relax</h6>
                                            <p class="card-text">Sit back and relax at home, while we get your things done.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section id="cxp-download">
                    <div className="container">
                        <div className="cxp-outer">
                            <div className="cxp-download-content">
                                <div className="cxp-download-btn">
                                    {/* <a href="#" style={{marginRight:"8rem"}}><img src="./images/home/google_play.png" alt='optional' /></a> */}
                                    {/*      <a href="#"><img src="./images/home/google_play.png" /></a> */}
                                </div>
                                <h4>Download India's Only One-Stop Financial & Non-Financial Asset Inheritance Solution.</h4>
                                <div >
                                    <a href="https://play.google.com/store/apps/details?id=com.app.yourdigiwillapp" target="_blank" className="cx-banner-btns"><img src="https://websiteimagesdigiwill.s3.ap-south-1.amazonaws.com/google-play-badge.png" style={{ height: "65.79px", width: "170px" }} alt="DigiWill App - Get it on Google Play Store" /></a>
                                    {/*    <a href="#"><img src="images/home/app_store.png" /></a> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </div>



    );
}

export default Claim;
